import React from "react"
import styled, { css } from "styled-components"
import { Link } from "gatsby"
import { rem } from "src/utils/styling"

const Container = styled.div`
  padding: 0px;
  @media screen and (min-width: 1100px) {
    padding: 20px;
  }
`

const Tag = styled.div`
  border-radius: 80px;
  text-transform: uppercase;
  color: white;
  font-weight: 900;
  font-size: 9px;
  letter-spacing: 2px;
  padding: 10px 20px;
  width: fit-content;
  margin: 0 auto 20px;
  ${props =>
    props.type === "prototyping" &&
    css`
      background: linear-gradient(260.25deg, #667eea -10.66%, #764ba2 113%);
    `} ${props =>
    props.type === "betaProduct" &&
    css`
      background: linear-gradient(260.25deg, #ff6a00 -10.66%, #ee0979 113%);
    `}  ${props =>
    props.type === "enhancements" &&
    css`
      background: linear-gradient(260.25deg, #2af598 -10.66%, #009efd 113%);
    `}
  @media screen and (min-width: 1100px) {
    font-size: 13px;
    margin: 0 0 20px;
  }
`

const CompanyLogo = styled.img`
  margin: 0 auto 20px;
  @media screen and (min-width: 1100px) {
    margin: 0 0 10px;
    height: 40px;
  }
`

const Title = styled.h3`
  font-weight: 900;
  font-size: 16px;
  line-height: 30px;
  color: #000000;
  margin-bottom: 15px;
  width: 100%;
  @media screen and (min-width: 1100px) {
    font-size: 19px;
    width: 80%;
  }
`

const Description = styled.p`
  font-weight: 500;
  font-size: 12px;
  line-height: 33px;
  color: #000000;
  opacity: 0.7;
  margin-bottom: 15px;
  @media screen and (min-width: 1100px) {
    font-size: 16px;
  }
`

const ToolsUsedContainer = styled.div`
  display: flex;
  margin-bottom: 10px;
  @media screen and (min-width: 1100px) {
    margin-bottom: 15px;
  }
  @media screen and (max-width: 550px) {
    justify-content: center;
    align-items: center;
  }
`

const ToolsUsed = styled.img`
  height: 50px;
  margin: 0 auto;
  @media screen and (min-width: 1100px) {
    margin: 0 10px 0 0;
  }
  @media screen and (max-width: 550px) {
    height: 20px;
    margin: 0 ${rem(10)}rem;
  }
`

const ReadMore = styled(Link)`
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: #577ada;
`

const CaseStudyLeft = ({
  type,
  typeText,
  companyLogo,
  title,
  description,
  toolsUsed,
  readMoreLink,
}) => {
  return (
    <Container>
      <Tag type={type}>{typeText}</Tag>
      <CompanyLogo src={companyLogo} />
      <Title>{title}</Title>
      <Description>{description}</Description>
      <ToolsUsedContainer>
        {toolsUsed.map((tool, i) => (
          <ToolsUsed key={i} src={tool} />
        ))}
      </ToolsUsedContainer>
      {readMoreLink && <ReadMore to={readMoreLink}>Read More</ReadMore>}
    </Container>
  )
}

export default CaseStudyLeft
