import Accurkardia from "src/images/logos/accur.svg"
import Canid from "src/images/logos/canid-logo.svg"
import Clarus from "src/images/logos/clarus.svg"

import Ishi from "src/images/logos/ishi-logo.png"
import M from "src/images/m.svg"
import ESports1 from "src/images/esports-logo.png"

const clientIcons = [
  [
    {
      image: Accurkardia,
      style: { height: 20, marginRight: 20 },
    },
    {
      image: Canid,
      style: { height: 20, marginRight: 0 },
    },
  ],
  [
    // {
    //   image: Clarus,
    //   style: { height: 30, marginRight: 20 },
    // },
    {
      image: ESports1,
      style: { height: 30, marginRight: 20, top: 4 },
    },

    {
      image: Ishi,
      style: { height: 25, marginRight: 0, position: "relative", top: "-4px" },
    },
  ],
]

export const clientIconsDesktop = [
  // [
  {
    image: Accurkardia,
    style: { height: 25, marginRight: 60 },
  },
  {
    image: Ishi,
    style: { height: 25, marginRight: 60 },
  },
  // ],
  // [
  {
    image: ESports1,
    style: { height: 30, marginRight: 60, position: "relative", top: 4 },
  },
  {
    image: M,
    style: { height: 35, marginRight: 60 },
  },
  {
    image: Clarus,
    style: { height: 30, marginRight: 60 },
  },
  {
    image: Canid,
    style: { height: 25, marginRight: 0 },
  },
  // ],
]

export default clientIcons
