import React from "react"
import styled from "styled-components"

const Wrapper = styled.a`
  width: 2rem;
  height: 2rem;
  display: block;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 100%;
`
function RoundIconButton(props) {
  return (
    <Wrapper
      href={props.link}
      style={{ backgroundImage: `url(${props.icon})` }}
      target="_blank"
    ></Wrapper>
  )
}
export default RoundIconButton
