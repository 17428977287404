import React from "react"
import HassleImage from "src/images/icons/hassle.png"
import Scale from "src/images/icons/workflow.svg"
import LowStatupCost from "src/images/icons/lowStartup.svg"
import PricingShock from "src/images/icons/pricing-shock.svg"
import Quality from "src/images/icons/quality.svg"

const benefitsData = [
  [
    {
      width: 64,
      height: 56,
      image: LowStatupCost,
      title: "Minimize start up costs",
      description:
        "Start with as little as a $100 task to get started and pay as you go",
    },
    {
      width: 57,
      height: 57,
      image: HassleImage,
      title: "Hassle-free",
      description:
        "We manage screening, legal on-boarding, and paying engineers",
    },
    {
      width: 90,
      height: 51,
      image: Scale,
      title: "Scale as Needed",
      description:
        "Begin with a single task and scale up to multiple concurrent tasks",
    },
  ],
  [
    {
      height: 60,
      width: 56,
      image: PricingShock,
      title: "Avoid Price Shocks",
      description:
        "Upfront fixed prices prevent unanticipated pricing variablity",
    },
    {
      height: 60,
      width: 60,
      image: Quality,
      title: "High Quality",
      description: (
        <p
          style={{
            position: "relative",
            left: "-7px",
            padding: 0,
            margin: 0,
            width: "105%",
          }}
        >
          Code written and reviewed by high quality Archimydes{" "}
          <a
            style={{ color: "#00b8c5" }}
            href="https://archimydes.dev/guild/"
            target="_blank"
          >
            Guild
          </a>{" "}
          members
        </p>
      ),
    },
  ],
]

export default benefitsData
