import React, { useContext } from "react"
import Logo from "./Logo/Logo"
import Container from "./Container"
import styled, { ThemeContext } from "styled-components"
import facebook from "../images/facebook.svg"
import twitter from "../images/twitter.svg"
import instagram from "../images/instagram.svg"
import linkedin from "../images/linkedin.svg"
import facebook_cyan from "../images/icons/cyan-facebook.svg"
import twitter_cyan from "../images/icons/cyan-twitter.svg"
import instagram_cyan from "../images/icons/cyan-instagram.svg"
import cyan_youtube from "../images/icons/cyan-youtube.svg"
import linkedin_cyan from "../images/icons/cyan-linkedin.svg"
import substack_cyan from "../images/icons/cyan-substack.svg"

import facebook_light from "../images/facebook_light.svg"
import twitter_light from "../images/twitter_light.svg"
import instagram_light from "../images/instagram_light.svg"
import linkedin_light from "../images/linkedin_light.svg"

import RoundIconButton from "./RoundIconButton"
import { rem } from "../utils/styling"
import { useEnvironment } from "src/utils/environment"

import {
  DEVICE_TYPE_DESKTOP,
  DEVICE_TYPE_MOBILE,
  useDeviceType,
} from "../utils/deviceType"

const Wrapper = styled.div`
  padding: ${rem(64)}rem 0 0;
  background: ${prop => prop.theme.footer.background};
`

const NewWrapper = styled.div`
  padding: ${props =>
    props.paddingTop ? `${rem(props.paddingTop)}rem 0 0` : `${rem(64)}rem 0 0`};

  background: #f5f5f5;
  border-top: 1px solid #e3e3e3;

  @media screen and (min-width: 1100px) {
    padding-top: 300px !important;
  }
`

const WrapperMini = styled.div`
  margin-top: 2rem;
  padding: ${rem(40)}rem 0 0;
  border-top: 2px solid white;
  @media screen and (min-width: 1100px) {
    margin-top: 5rem;
  }
`

const Flex = styled.div`
  @media screen and (min-width: 1100px) {
    display: flex;
    justify-content: space-between;
  }
`

const HorizontalNavList = styled.ul`
  margin: 0;
  padding: 0;
  @media screen and (min-width: 1100px) {
    display: flex;
  }
`

const HorizontalNavListMobile = styled.ul`
  display: flex;
  justify-content: center;
  @media screen and (min-width: 1100px) {
    justify-content: flex-start;
  }
`

const HorizontalNavListItem = styled.li`
  display: block;
  margin: 0;
  margin-right: ${rem(15)}rem;
  padding: 0;
  text-align: center;
  @media screen and (min-width: 1100px) {
    margin-right: ${rem(24)}rem;
    text-align: left;
  }
  &:last-child {
    margin-right: 0;
  }
`

const VerticalNavList = styled.ul`
  display: block;
  margin: 0;
  padding: 0;
  margin-top: ${rem(24)}rem;
  @media screen and (min-width: 1100px) {
    margin-top: ${rem(30)}rem;
  }
`

const VerticalNavListItem = styled.li`
  display: block;
  margin: 0;
  padding: 0;
  margin-bottom: ${rem(24)}rem;
  margin-left: 0;
  @media screen and (min-width: 1100px) {
    margin-left: 0;
  }
`

const BackToTopButton = styled.button`
  text-align: center;
  height: ${rem(68)}rem;
  padding-top: ${rem(42)}rem;
  background: transparent;
  position: absolute;
  right: 0;
  top: 0;
  font-size: ${rem(16)}rem;
  margin-top: ${rem(200)}rem;
  border: 0;
  color: #162b43;
`

const NavHeader = styled.h6`
  font-size: ${rem(22)}rem;
  color: #162b43;
  color: ${prop => prop.theme.footer.highlight};

  font-weight: 800;
  margin: 0;
`

const FooterNavLink = styled.a`
  font-size: ${rem(16)}rem;
  color: #162b43;
  font-weight: normal;
  color: ${prop => prop.theme.footer.color};
  text-decoration: none;
  cursor: pointer;
  @media screen and (min-width: 1100px) {
    font-size: ${rem(20)}rem;
  }
`

const LightFooterNavLink = styled.a`
  font-size: ${rem(16)}rem;
  font-weight: normal;
  color: #000;
  text-decoration: none;
  cursor: pointer;
  @media screen and (min-width: 1100px) {
    font-size: ${rem(17)}rem;
  }
`

const CopyRightText = styled.p`
  font-size: ${rem(20)}rem;
  margin: 0;
  color: ${prop => prop.theme.footer.color};
`

const CopyRightWrapper = styled.div`
  padding: ${rem(34)}rem 0;
  margin-top: ${rem(80)}rem;
  border-top: 1px solid ${props => props.theme.footer.color};
`

const DarkCopyRightWrapper = styled.div`
  padding: ${rem(28)}rem 0;
  margin-top: ${rem(80)}rem;
  background-color: #000;
`
const DarkCopyRightContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  @media screen and (min-width: 1100px) {
    flex-direction: row;
  }
`

const DarkCopyRightContentRight = styled(DarkCopyRightContent)`
  justify-content: center;
  @media screen and (min-width: 1100px) {
    flex-direction: row;
  }
`
const LightCopyRightText = styled(CopyRightText)`
  font-size: ${rem(16)}rem;
  margin: ${rem(10)}rem 0;
  @media screen and (min-width: 1100px) {
    margin: 0;
  }
`
const LightCopyRightLink = styled.a`
  font-size: ${rem(16)}rem;
  margin: 0;
  color: #fff;
  margin: ${rem(10)}rem 0;
  @media screen and (min-width: 1100px) {
    padding-right: ${rem(40)}rem;
    margin: 0;
    &:last-child {
      padding-right: 0;
    }
  }
`

const CustomLink = styled.div`
  div {
    display: flex;
    justify-content: center;
    @media screen and (min-width: 1100px) {
      display: block;
    }
  }
`

const footerMenu = [
  {
    label: "Offerings",
    menu: [
      {
        label: "Code Only",
        link: "/comparison",
      },
      {
        label: "Full Service",
        link: "/comparison#fullservice",
      },
    ],
  },
  {
    label: "Community",
    menu: [
      {
        label: "Developers",
        link: "/guild",
      },
      {
        label: "Fourth Act",
        stagingLink: "https://s.archimydes.dev/fourthact/",
        prodLink: "https://archimydes.dev/fourthact/",
      },
    ],
  },
  {
    label: "Company",
    menu: [
      {
        label: "Overview",
        link: "/",
      },
      {
        label: "About Us",
        link: "/about-us",
      },
      {
        label: "Privacy Policy",
        link: "/privacy-policy",
      },
      {
        label: "Terms of Use",
        link: "/terms-of-use",
      },
    ],
  },
]
const NewfooterMenuDesktop = [
  {
    label: "Apprenticeship",
    menu: [
      {
        label: "The Program",
        link: "/apprenticeship",
      },
      {
        label: "For Apprentices",
        link: "/apprenticeship/apprentice",
      },
      {
        label: "For Startups",
        link: "/apprenticeship/startups",
      },
      {
        label: "For Social Enterprises",
        link: "/apprenticeship/social-enterprise",
      },
      {
        label: "For Mentors",
        link: "/apprenticeship/mentor",
      },
    ],
  },
  {
    label: "Platform",
    menu: [
      {
        label: "Fourth Act",
        stagingLink: "https://s.archimydes.dev/fourthact/",
        prodLink: "https://archimydes.dev/fourthact/",
      },
      {
        label: "Developer Guild",
        link: "/guild",
      },
      {
        label: "Our Methodology",
        link: "/how-it-works",
      },
    ],
  },
  {
    label: "Company",
    menu: [
      // {
      //   label: "Case Studies",
      //   link: "/#case-studies",
      // },
      {
        label: "About Us",
        link: "/about-us",
      },
    ],
  },
]
const NewfooterMenuMobile = [
  {
    label: "Platform",
    menu: [
      {
        label: "Fourth Act",
        stagingLink: "https://s.archimydes.dev/fourthact/",
        prodLink: "https://archimydes.dev/fourthact/",
      },
      {
        label: "Developer Guild",
        link: "/guild",
      },
      {
        label: "Our Methodology",
        link: "/how-it-works",
      },
    ],
  },
  {
    label: "Apprenticeship",
    menu: [
      {
        label: "The Program",
        link: "/apprenticeship",
      },
      {
        label: "For Apprentices",
        link: "/apprenticeship/apprentice",
      },
      {
        label: "For Startups",
        link: "/apprenticeship/startups",
      },
      {
        label: "For Social Enterprises",
        link: "/apprenticeship/social-enterprise",
      },
      {
        label: "For Mentors",
        link: "/apprenticeship/mentor",
      },
    ],
  },
  {
    label: "Company",
    menu: [
      // {
      //   label: "Case Studies",
      //   link: "/#case-studies",
      // },
      {
        label: "About Us",
        link: "/about-us",
      },
    ],
  },
]

const NewfooterMenu = [
  {
    label: "Overview",
    menu: [
      {
        label: "Home",
        link: "/",
      },
      {
        label: "Apprenticeship",
        link: "/apprenticeship",
      },
      {
        label: "Code as a Service",
        link: "/code",
      },
    ],
  },
  {
    label: "Platform",
    menu: [
      {
        label: "Fourth Act",
        stagingLink: "https://s.archimydes.dev/fourthact/",
        prodLink: "https://archimydes.dev/fourthact/",
      },
      {
        label: "Developer Guild",
        link: "/guild",
      },
      {
        label: "Our Methodology",
        link: "/how-it-works",
      },
    ],
  },
  {
    label: "Company",
    menu: [
      // {
      //   label: "Case Studies",
      //   link: "/#case-studies",
      // },
      {
        label: "About Us",
        link: "/about-us",
      },
    ],
  },
]

const footerMiniMenu = [
  {
    label: "Contact Us",
    link: "/signup",
  },
  {
    label: "Terms of Use",
    link: "/terms-of-use",
  },
  {
    label: "Privacy Policy",
    link: "/privacy-policy",
  },
]

const HorizontalNavListItemForMainMenu = styled(HorizontalNavListItem)`
  margin-right: 0;
  @media screen and (min-width: 1100px) {
    margin-right: 6rem;
    &::last-child {
      margin-right: 0;
    }
  }
`

const HorizontalNavListForMainMenu = styled(HorizontalNavList)`
  margin-top: ${rem(40)}rem;
  @media screen and (min-width: 1100px) {
    margin-top: 0;
    margin-left: 6rem;
  }
`

const FooterMiniCopyrightWrapper = styled.div`
  margin-left: 0rem;
  text-align: center;
  margin-top: 1rem;
  @media screen and (min-width: 1100px) {
    margin-left: 3rem;
    text-align: left;
    margin-top: 0;
  }
`

const HorizontalNavListItemForFooterMini = styled(HorizontalNavListItem)`
  margin-right: 0;
  margin-bottom: 1rem;
  @media screen and (min-width: 1100px) {
    margin-bottom: 0;
  }
`

function Footer(props) {
  const theme = useContext(ThemeContext)
  const environment = useEnvironment()

  return (
    <Wrapper>
      <Container>
        <Flex>
          <div>
            <CustomLink to="/">
              <Logo />
            </CustomLink>
            <HorizontalNavListMobile style={{ marginTop: "2rem" }}>
              <HorizontalNavListItem>
                <RoundIconButton
                  link="https://twitter.com/archimydes"
                  icon={theme.logo === "light" ? twitter_light : twitter}
                />
              </HorizontalNavListItem>
              <HorizontalNavListItem>
                <RoundIconButton
                  link="https://www.linkedin.com/company/14778870/admin/"
                  icon={theme.logo === "light" ? linkedin_light : linkedin}
                />
              </HorizontalNavListItem>
              <HorizontalNavListItem>
                <RoundIconButton
                  link="https://www.facebook.com/archimydes"
                  icon={theme.logo === "light" ? facebook_light : facebook}
                />
              </HorizontalNavListItem>
              <HorizontalNavListItem>
                <RoundIconButton
                  link="https://www.instagram.com/archimydes/"
                  icon={theme.logo === "light" ? instagram_light : instagram}
                />
              </HorizontalNavListItem>
            </HorizontalNavListMobile>
          </div>
          <div>
            <HorizontalNavListForMainMenu>
              {footerMenu.map((val, i) => {
                return (
                  <HorizontalNavListItemForMainMenu key={i}>
                    <div>
                      <NavHeader>{val.label}</NavHeader>
                    </div>
                    <VerticalNavList>
                      {val.menu.map((link, i) => {
                        if (link.label === "Fourth Act") {
                          return (
                            <VerticalNavListItem key={i}>
                              <FooterNavLink
                                href={
                                  environment == "staging"
                                    ? link.stagingLink
                                    : link.prodLink
                                }
                              >
                                {link.label}
                              </FooterNavLink>
                            </VerticalNavListItem>
                          )
                        } else {
                          return (
                            <VerticalNavListItem key={i}>
                              <FooterNavLink href={link.link}>
                                {link.label}
                              </FooterNavLink>
                            </VerticalNavListItem>
                          )
                        }
                      })}
                    </VerticalNavList>
                  </HorizontalNavListItemForMainMenu>
                )
              })}
            </HorizontalNavListForMainMenu>
          </div>
        </Flex>
      </Container>
      <CopyRightWrapper>
        <Container>
          <CopyRightText>&copy; 2020 Archimydes</CopyRightText>
          {/* <BackToTopButton> <TopIcon role="presentation" /> Back to top</BackToTopButton> */}
        </Container>
      </CopyRightWrapper>
    </Wrapper>
  )
}

export function FooterMini(props) {
  const theme = useContext(ThemeContext)
  console.log(theme)
  return (
    <WrapperMini>
      <Container>
        <Flex>
          <div>
            <HorizontalNavListMobile>
              <HorizontalNavListItem>
                <RoundIconButton
                  link="https://twitter.com/archimydes"
                  icon={theme.logo === "light" ? twitter_light : twitter}
                />
              </HorizontalNavListItem>
              <HorizontalNavListItem>
                <RoundIconButton
                  link="https://www.linkedin.com/company/14778870/admin/"
                  icon={theme.logo === "light" ? linkedin_light : linkedin}
                />
              </HorizontalNavListItem>
              <HorizontalNavListItem>
                <RoundIconButton
                  link="https://www.facebook.com/archimydes"
                  icon={theme.logo === "light" ? facebook_light : facebook}
                />
              </HorizontalNavListItem>
              <HorizontalNavListItem>
                <RoundIconButton
                  link="https://www.instagram.com/archimydes/"
                  icon={theme.logo === "light" ? instagram_light : instagram}
                />
              </HorizontalNavListItem>
            </HorizontalNavListMobile>
          </div>
          <Flex>
            <HorizontalNavListForMainMenu>
              {footerMiniMenu.map((link, i) => {
                return (
                  <HorizontalNavListItemForFooterMini key={i}>
                    <FooterNavLink href={link.link}>
                      <strong>{link.label}</strong>
                    </FooterNavLink>
                  </HorizontalNavListItemForFooterMini>
                )
              })}
            </HorizontalNavListForMainMenu>
            <FooterMiniCopyrightWrapper>
              <CopyRightText>&copy; 2020 Archimydes</CopyRightText>
            </FooterMiniCopyrightWrapper>
          </Flex>
        </Flex>
      </Container>
    </WrapperMini>
  )
}

export default Footer

export function LightFooter(props) {
  const theme = useContext(ThemeContext)
  const environment = useEnvironment()

  const deviceType = useDeviceType()

  const menuToUse =
    deviceType === DEVICE_TYPE_MOBILE
      ? NewfooterMenuMobile
      : NewfooterMenuDesktop

  return (
    <NewWrapper paddingTop={props.paddingTop}>
      <Container>
        <Flex>
          <div>
            <CustomLink to="/">
              <Logo logo="dark" />
            </CustomLink>
            <HorizontalNavListMobile style={{ marginTop: "2rem" }}>
              <HorizontalNavListItem>
                <RoundIconButton
                  link="https://twitter.com/archimydes"
                  icon={twitter_cyan}
                />
              </HorizontalNavListItem>
              <HorizontalNavListItem>
                <RoundIconButton
                  link="https://www.linkedin.com/company/14778870/admin/"
                  icon={linkedin_cyan}
                />
              </HorizontalNavListItem>
              <HorizontalNavListItem>
                <RoundIconButton
                  link="https://www.youtube.com/channel/UCducViXtaHTKpBNuThVzjYQ"
                  icon={cyan_youtube}
                />
              </HorizontalNavListItem>
              <HorizontalNavListItem>
                <RoundIconButton
                  link="https://archimydes.substack.com/"
                  icon={substack_cyan}
                />
              </HorizontalNavListItem>
            </HorizontalNavListMobile>
          </div>
          <div>
            <HorizontalNavListForMainMenu>
              {menuToUse.map((val, i) => {
                return (
                  <HorizontalNavListItemForMainMenu key={i}>
                    <div>
                      <NavHeader>{val.label}</NavHeader>
                    </div>
                    <VerticalNavList>
                      {val.menu.map((link, i) => {
                        if (link.label === "Fourth Act") {
                          return (
                            <VerticalNavListItem key={i}>
                              <LightFooterNavLink
                                href={
                                  environment === "staging"
                                    ? link.stagingLink
                                    : link.prodLink
                                }
                              >
                                {link.label}
                              </LightFooterNavLink>
                            </VerticalNavListItem>
                          )
                        } else {
                          return (
                            <VerticalNavListItem key={i}>
                              <LightFooterNavLink href={link.link}>
                                {link.label}
                              </LightFooterNavLink>
                            </VerticalNavListItem>
                          )
                        }
                      })}
                    </VerticalNavList>
                  </HorizontalNavListItemForMainMenu>
                )
              })}
            </HorizontalNavListForMainMenu>
          </div>
        </Flex>
      </Container>
      <DarkCopyRightWrapper>
        <Container>
          <DarkCopyRightContent>
            <LightCopyRightText>
              &copy; 2021. All rights reserved
            </LightCopyRightText>
            <DarkCopyRightContentRight>
              <LightCopyRightLink href="/privacy-policy">
                Privacy Policy
              </LightCopyRightLink>
              <LightCopyRightLink href="/terms-of-use">
                Terms & Conditions
              </LightCopyRightLink>
            </DarkCopyRightContentRight>
          </DarkCopyRightContent>
          {/* <BackToTopButton> <TopIcon role="presentation" /> Back to top</BackToTopButton> */}
        </Container>
      </DarkCopyRightWrapper>
    </NewWrapper>
  )
}
