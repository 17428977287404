import React from "react"
import { MobileOnlyCarousel } from "src/components/Carousel"
import Container from "src/components/Container"
import styled from "styled-components"
import { rem } from "src/utils/styling"
import TitleAndBody from "src/components/TitleAndBody"
import benefitsData from "src/data/benefitsData"
import {
  DEVICE_TYPE_DESKTOP,
  DEVICE_TYPE_MOBILE,
  useDeviceType,
} from "../utils/deviceType"

const BenefitContainer = styled.div`
  margin: 9rem 0 10rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (min-width: 551px) and (max-width: 1100px) {
    margin: 0rem 0 3rem 0;
  }
  @media screen and (max-width: 550px) {
    margin: 1rem 0;
  }
`

const BenefitContainerCardText = styled.p`
  font-family: Nunito;
  font-size: ${rem(14)}rem;
  color: #000;
  line-height: ${rem(28)}rem;
  font-weight: 300;
  text-align: center;
  max-width: 350px;
  margin-left: auto;
  margin-right: auto;
  @media screen and (min-width: 1100px) {
    font-size: 17px;
    line-height: 34px;
    width: 90%;
  }
  @media screen and (max-width: 550px) {
    line-height: ${rem(28)}rem;
  }
`

const BenefitContainerContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  margin: ${rem(10)}rem ${rem(60)}rem;
  @media screen and (min-width: 1100px) {
    flex-direction: row;
    flex-wrap: wrap;
  }
  @media screen and (max-width: 550px) {
    margin: ${rem(20)}rem 0 0;
  }
`

const BenefitContainerCardTitle = styled.h3`
  text-align: center;
  font-size: ${rem(19)}rem;
  color: #000;
  font-weight: 900;
  padding-bottom: ${rem(10)}rem;
  @media screen and (min-width: 1100px) {
    font-size: 22px;
  }
`

const BenefitContainerCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  background: #ffffff;
  /* border: 1px solid #eeeeee; */
  border-radius: 8px;
  margin: 0 ${rem(10)}rem;
  padding: ${rem(20)}rem ${rem(20)}rem;
  min-height: ${rem(170)}rem;
  transition: all 0.3s ease;
  &:hover {
    transform: scale(1.01);
    box-sizing: border-box;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    ${BenefitContainerCardTitle} {
      color: #00b8c5;
      transition: all 0.3s ease;
    }
  }
  @media screen and (min-width: 1100px) {
    flex: 1;
    max-width: 320px;
    justify-content: center;
    align-items: center;
  }
`

const BenefitContainerCardImage = styled.img`
  align-self: center !important;
  width: ${prop => (prop.idx && prop.idx === 2 ? rem(80) : rem(60))}rem;
  height: ${rem(60)}rem;
  margin-bottom: ${prop => (prop.idx && prop.idx === 2 ? 15 : 25)}px !important;
  @media screen and (min-width: 1100px) {
    width: ${prop => (prop.width ? prop.width : 65)}px;
    height: ${prop => (prop.height ? prop.height : 65)}px;
    align-self: flex-start;
    margin-right: ${rem(20)}rem;
    margin-bottom: ${prop =>
      prop.idx && prop.idx === 2 ? 30 : 25}px !important;
  }
`
const CardRow = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: ${rem(10)}rem 0 0;
  &:last-child {
    margin: ${rem(12)}rem 0;
  }
  @media screen and (min-width: 1100px) {
    flex-direction: row;
    justify-content: center;
  }
  @media screen and (max-width: 550px) {
    margin: 0;
  }
`

const BenefitContainerCardTextContainer = styled.div``
const ContainerWide = styled(Container)`
  padding-right: 0;
  padding-left: 0;
`
const Benefits = () => {
  const deviceType = useDeviceType(TitleAndBody)

  return (
    <ContainerWide>
      <BenefitContainer>
        <TitleAndBody
          type="benefits"
          title="Benefits of Leveraging Archimydes"
        />
        {deviceType === DEVICE_TYPE_DESKTOP ? (
          <>
            <CardRow>
              <BenefitContainerContent>
                <MobileOnlyCarousel>
                  {benefitsData[0].map((benefit, idx) => (
                    <div style={{ flex: 1 }} key={benefit.title}>
                      <BenefitContainerCard>
                        <BenefitContainerCardImage
                          idx={idx}
                          src={benefit.image}
                          alt={benefit.title}
                          height={benefit.height}
                          width={benefit.width}
                        />
                        <BenefitContainerCardTextContainer>
                          <BenefitContainerCardTitle>
                            {benefit.title}
                          </BenefitContainerCardTitle>
                          <BenefitContainerCardText>
                            {benefit.description}
                          </BenefitContainerCardText>
                        </BenefitContainerCardTextContainer>
                      </BenefitContainerCard>
                    </div>
                  ))}
                </MobileOnlyCarousel>
              </BenefitContainerContent>
            </CardRow>
            <br />
            <CardRow>
              <BenefitContainerContent>
                <MobileOnlyCarousel>
                  {benefitsData[1].map((benefit, idx) => (
                    <div style={{ flex: 1 }} key={benefit.title}>
                      <BenefitContainerCard>
                        <BenefitContainerCardImage
                          idx={idx}
                          src={benefit.image}
                          alt={benefit.title}
                          height={benefit.height}
                          width={benefit.width}
                        />
                        <BenefitContainerCardTextContainer>
                          <BenefitContainerCardTitle>
                            {benefit.title}
                          </BenefitContainerCardTitle>
                          <BenefitContainerCardText>
                            {benefit.description}
                          </BenefitContainerCardText>
                        </BenefitContainerCardTextContainer>
                      </BenefitContainerCard>
                    </div>
                  ))}
                </MobileOnlyCarousel>
              </BenefitContainerContent>
            </CardRow>
          </>
        ) : (
          <>
            <BenefitContainerContent>
              <MobileOnlyCarousel>
                {[...benefitsData[0], ...benefitsData[1]].map(
                  (benefit, idx) => (
                    <div style={{ flex: 1 }} key={benefit.title}>
                      <BenefitContainerCard>
                        <BenefitContainerCardImage
                          idx={idx}
                          src={benefit.image}
                          alt={benefit.title}
                          height={benefit.height}
                          width={benefit.width}
                        />
                        <BenefitContainerCardTextContainer>
                          <BenefitContainerCardTitle>
                            {benefit.title}
                          </BenefitContainerCardTitle>
                          <BenefitContainerCardText>
                            {benefit.description}
                          </BenefitContainerCardText>
                        </BenefitContainerCardTextContainer>
                      </BenefitContainerCard>
                    </div>
                  )
                )}
              </MobileOnlyCarousel>
            </BenefitContainerContent>
          </>
        )}
      </BenefitContainer>
    </ContainerWide>
  )
}

export default Benefits
