import { useEffect, useState } from "react"

export const DEVICE_TYPE_MOBILE = "mobile"
export const DEVICE_TYPE_DESKTOP = "desktop"

export function useDeviceType() {
  const getDeviceType = () => {
    return window.innerWidth >= 1100 ? DEVICE_TYPE_DESKTOP : DEVICE_TYPE_MOBILE
  }
  const [deviceType, setDeviceType] = useState(DEVICE_TYPE_DESKTOP)
  useEffect(() => {
    const resizeHandler = () => {
      setDeviceType(getDeviceType())
    }
    resizeHandler()
    window.addEventListener("resize", resizeHandler)
    return () => {
      window.removeEventListener("resize", resizeHandler)
    }
  }, [])
  return deviceType
}
