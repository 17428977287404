import sketch from "src/images/icons/sketch.png"
import figma from "src/images/icons/figma.png"
import adobe from "src/images/icons/adobe-xd.svg"
import react from "src/images/icons/react.svg"
import jIcon from "src/images/icons/j-dark.svg"
import script from "src/images/icons/script-dark.png"
import atlassain from "src/images/icons/atlassain.svg"
import sIcon from "src/images/icons/s.svg"
import salesforce from "src/images/icons/salesforce.svg"
import slack from "src/images/icons/slack.png"
import microservice from "src/images/icons/microservice.svg"

const cardItems = [
  [
    {
      images: [
        {
          image: sketch,
          alt: "sketch",
        },
        {
          image: figma,
          alt: "figma",
        },
        {
          image: adobe,
          alt: "adobe",
        },
      ],
      title: "Design to Code",
      descriptions: [
        "Convert Hi-Fi mocks to pixel-perfect websites.",
        "Readable, usable & production ready code.",
        "You pay only if we meet your success criteria.",
      ],
    },
    {
      images: [
        {
          image: react,
          alt: "react",
        },
      ],
      title: "React Components",
      descriptions: [
        "Scalable React code.",
        "React code that is reusable and efficiently written.",
        "Build out your design system.",
      ],
    },
    {
      images: [
        {
          image: jIcon,
          alt: "jIcon",
        },
      ],
      title: "MVP & MVT",
      descriptions: [
        "Go from click-through prototype to an MVP in 6 weeks.",
        "Continue with a Minimum Virtual Team (MVT) post MVP.",
        "A fully functional team, including a BA, Full-Stack Engineering Lead, & developers.",
      ],
    },
  ],
  [
    {
      images: [
        {
          image: script,
          alt: "script",
        },
      ],
      title: "Enhancements",
      descriptions: [
        "UI and frontend logic enhancements and changes.",
        "Backend API development and enhancements.",
        "Re-factor existing codebases.",
      ],
    },
    {
      images: [
        {
          image: atlassain,
          alt: "atlassian",
        },
        {
          image: sIcon,
          alt: "shopify",
        },
        {
          image: salesforce,
          alt: "salesforce",
        },
        {
          image: slack,
          alt: "slack",
        },
      ],
      title: "Plugins",
      descriptions: [
        "Plugins for JIRA, Shopify, Salesforce and other apps.",
        "React Frontend for plugins if required.",
        "Integration with backend APIs and microservice.",
      ],
    },
    {
      images: [
        {
          image: microservice,
          alt: "microservice",
        },
      ],
      title: "Microservices",
      descriptions: [
        "Event-driven code for AWS Lambda, Azure Functions, and GCP Cloud Functions.",
        "Performance optimization of serverless functions.",
        "Assistance with monolith carve outs.",
      ],
    },
  ],
]

export default cardItems

export const enhancementsCardItemsData = [
  [
    {
      images: [
        {
          image: sketch,
          alt: "sketch",
        },
        {
          image: adobe,
          alt: "adobe",
        },
        {
          image: figma,
          alt: "figma",
        },
      ],
      to: "#design",
      color: "#FFF6D7",
      shadow: "6px 6px 0px #F4EAC8",
      title: "Design to Code",
      descriptions:
        "Convert Hi-Fi mocks into pixel-perfect, production-ready code.",
    },
    {
      images: [
        {
          image: react,
          alt: "react",
        },
      ],
      color: "#DCF7E8",
      shadow: "6px 6px 0px #BBE6CE",
      title: "React Components",
      descriptions:
        "Obtain scalable, reusable and efficiently written React code.",
    },
    {
      images: [
        {
          image: jIcon,
          alt: "jIcon",
        },
      ],
      color: "#E1F5FE",
      shadow: "6px 6px 0px #B7DEF0",
      title: "Micro Frontends",
      descriptions: "Scale your frontend development using micro frontends.",
    },
  ],
  [
    {
      images: [
        {
          image: script,
          alt: "script",
        },
      ],
      color: "#FFEBEE",
      shadow: "6px 6px 0px #F1C7CD",
      title: "Enhancements",
      descriptions:
        "Request UI, frontend, API and backend features and bug fixes.",
    },
    {
      images: [
        {
          image: atlassain,
          alt: "atlassian",
        },
        {
          image: sIcon,
          alt: "shopify",
        },
        {
          image: salesforce,
          alt: "salesforce",
        },
        {
          image: slack,
          alt: "slack",
        },
      ],
      to: "#plugin",
      title: "Plugins",
      color: "#FFF6D7",
      shadow: "6px 6px 0px #F4EAC8",

      descriptions:
        "Develop custom plugins for your mobile and web applications.",
    },
    {
      images: [{ image: microservice, alt: "microservice" }],
      color: "#EDE7F6",
      shadow: "6px 6px 0px #D5C4ED",
      to: "#microservice",
      title: "Microservices",
      descriptions:
        "Build standalone microservices or execute monolith carveouts.",
    },
  ],
]
