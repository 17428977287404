import React, { useEffect, useRef, useState } from "react"
import { Link, navigate } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import Logo from "./Logo/Logo"
import styled, { css } from "styled-components"
import Button from "./Button"
import { rem } from "../utils/styling"
import {
  DEVICE_TYPE_DESKTOP,
  DEVICE_TYPE_MOBILE,
  useDeviceType,
} from "../utils/deviceType"
import { NewResponsiveMenu, ResponsiveMenu } from "./ResponsiveMenu"
import SrOnly from "src/components/SrOnly/SrOnly"
import lightLogo from "src/images/archimydes_logo_light.svg"
import DefaultContainer from "src/components/Container"
import { HiChevronRight } from "react-icons/hi"
import { ReactTypeformEmbed } from "react-typeform-embed"
import { PageButton } from "src/pages/code"
import { openPopupWidget } from "react-calendly"
import { useLocation } from "@reach/router"
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io"
import scrollTo from "gatsby-plugin-smoothscroll"

const Container = styled.div`
  width: 100%;
  padding: 0 26px;
  margin: auto;
  position: relative;
  box-sizing: border-box;
  overflow-x: hidden;
  display: flex;
  justify-content: space-between;
  @media screen and (min-width: 1100px) {
    width: 1100px;
    padding: 0 30px;
  }
  @media screen and (min-width: 1280px) {
    width: 1240px;
  }
  @media screen and (min-width: 1600px) {
    width: 1440px;
  }
`

const Topbar = styled.header`
  padding-top: ${rem(26)}rem;
  padding-bottom: ${rem(26)}rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (min-width: 1100px) {
    padding-top: ${rem(40)}rem;
    padding-bottom: ${rem(40)}rem;
  }
`

const NewTopbar = styled(Topbar)`
  @media screen and (min-width: 1100px) {
    padding-top: ${rem(20)}rem;
    padding-bottom: ${rem(20)}rem;
  }
`

const Brand = styled(Link)`
  width: ${rem(147)}rem;
  height: ${rem(18)}rem;
  left: 0;
  top: 0;
  background-size: contain;
  margin-top: ${rem(8)}rem;
  display: block;
  background-image: url(${lightLogo});
  background-repeat: no-repeat;
  @media screen and (min-width: 1100px) {
    margin-top: ${rem(16)}rem;
    width: ${rem(206)}rem;
    height: ${rem(24)}rem;
  }
`

const StickyHeader = styled.header`
  padding-top: ${rem(20)}rem;
  padding-bottom: ${rem(20)}rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #000725;
  position: fixed;
  z-index: 99;
  width: 100%;
  @media screen and (min-width: 1100px) {
    padding-top: ${rem(40)}rem;
    padding-bottom: ${rem(40)}rem;
  }
`

const TaskStickyHeader = styled.header`
  padding-top: ${rem(20)}rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000725;
  position: fixed;
  z-index: 99;
  height: 78px;
  width: 100%;
`

const NewTaskStickyHeader = styled(TaskStickyHeader)`
  padding-top: 0;
  z-index: 9999;
  top: 0;
  background: rgba(255, 255, 255, 0.7);
  border: 1px solid #eeeeee;
  backdrop-filter: blur(10px);
  ${props =>
    props.dark &&
    css`
      background-color: #000725;
      border: none;
    `}
`

const HorizontalNavList = styled.ul`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
`

const HorizontalNavListItem = styled.li`
  display: block;
  margin: 0 ${rem(40)}rem 0 0;
  padding: 0;

  &:last-child {
    margin-right: 0;
  }
`

const HorizontalNavListItemMidSpace = styled(HorizontalNavListItem)`
  margin-right: ${rem(58)}rem;
`

const HorizontalNavListItemSpaced = styled(HorizontalNavListItem)`
  margin-right: ${rem(96)}rem;
`

const HorizontalNavListSeparator = styled.li`
  padding: 0;
  margin: 0 ${rem(40)}rem 0 0;
  width: 1px;
  height: ${rem(48)}rem;
  background: white;
  display: block;
`

const LearnMoreButton = styled.div`
  font-size: ${rem(12)}rem;
  padding: ${rem(4)}rem ${rem(10)}rem;
  display: inline-block;
  box-sizing: border-box;
  font-weight: 600;
  border-radius: 6.25rem;
  background: none;
  outline: none;
  cursor: pointer;
  background: #fbbc62;
  color: #000725;
  border: 2px solid #fbbc62;
  margin-left: 25px;
  @media screen and (min-width: 1100px) {
    font-size: ${rem(18)}rem;
    padding: ${rem(13)}rem ${rem(40)}rem;
  }
`

const HeaderLinks = styled(Link)`
  font-weight: 400;
  font-size: 15px;
  color: black;
  opacity: 0.7;
  ${props =>
    props.dark &&
    css`
      color: white;
      opacity: 1;
    `}
`

const ApprenticeHeaderLinks = styled(HeaderLinks)`
  margin: 0 ${rem(20)}rem;
  ${props =>
    props.active === "true" &&
    css`
      color: #00b8c5;
      font-weight: 600;
    `}
`

const HeaderLinksExternalLinks = styled.a`
  font-weight: 400;
  font-size: 15px;
  color: black;
  opacity: 0.7;
  ${props =>
    props.dark &&
    css`
      color: white;
      opacity: 1;
    `}
`

const HeaderExpansion = styled.div`
  display: none;
  padding-top: ${rem(26)}rem;
  padding-bottom: ${rem(26)}rem;
  align-items: center;
  box-sizing: border-box;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(8, 15, 42, 1);
  background: rgba(0, 7, 37, 0.9);
  backdrop-filter: blur(13px);
  z-index: 2;
  @media screen and (min-width: 1100px) {
    &.active {
      display: flex;
    }

    padding-top: ${rem(40)}rem;
    padding-bottom: ${rem(40)}rem;
    height: ${rem(160)}rem;
    margin-bottom: ${rem(-160)}rem;
  }
`

const Wrapper = styled.div`
  transition: background-color 0.2s;
  position: relative;

  &.expanded {
    background-color: rgba(0, 7, 37, 0.9);
  }
  .full-width {
    width: 100%;
  }
`

const HeaderButton = styled(Button)`
  display: flex;
  align-items: center;
`

const MobileHeader = styled.div`
  z-index: 18;
  background: rgba(255, 255, 255, 0.7);
  border: 1px solid #eeeeee;
  backdrop-filter: blur(20px);
  width: 100%;
`

const LanMobileHeader = styled(MobileHeader)`
  position: fixed;
  ${props =>
    props.dark &&
    css`
      background-color: #000725;
      border: none;
    `}
`

const HeaderIndicator = styled.div`
  width: 100%;
  padding: ${rem(22)}rem 0 ${rem(40)}rem;
  z-index: 10;
  background: rgba(255, 255, 255, 0.7);
  border: 1px solid #eeeeee;
  backdrop-filter: blur(10px);
  display: flex;
  justify-content: center;
  position: fixed;
  top: ${rem(70)}rem;
  @media screen and (min-width: 1100px) {
    top: ${rem(90)}rem;
    z-index: 9999;
  }
`
const IndicatorContainer = styled.div`
  position: relative;
`

const HorizonatalLine = styled.div`
  height: ${rem(1)}rem;
  width: ${rem(270)}rem;
  background-color: #c1c1c1;
  @media screen and (min-width: 768px) {
    width: ${rem(560)}rem;
  }
`
const IndicatorOne = styled(Link)`
  position: absolute;
  top: -5px;
  left: -35px;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const IndicatorTwo = styled(IndicatorOne)`
  left: calc(25% - 20px);
  @media screen and (min-width: 768px) {
    left: calc(35% - 20px);
  }
`
const IndicatorThree = styled(IndicatorOne)`
  left: calc(40%);
  @media screen and (min-width: 768px) {
    left: calc(60%);
  }
`
const IndicatorFour = styled(IndicatorOne)`
  left: unset;
  right: -60px;
  @media screen and (min-width: 768px) {
    right: -30px;
  }
`
const IndicatorOneBullet = styled.div`
  border: 1px solid #c1c1c1;
  background-color: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(10px);
  height: 10px;
  width: 10px;
  border-radius: 50%;
  ${props =>
    props.active &&
    css`
      border: 3px solid #00b8c5;
      height: 12px;
      width: 12px;
    `}
`
const IndicatorTwoBullet = styled(IndicatorOneBullet)`
  ${props =>
    props.active &&
    css`
      border: 3px solid #00b8c5;
      height: 12px;
      width: 12px;
    `}
`
const IndicatorThreeBullet = styled(IndicatorOneBullet)`
  ${props =>
    props.active &&
    css`
      border: 3px solid #00b8c5;
      height: 12px;
      width: 12px;
    `}
`

const IndicatorFourBullet = styled(IndicatorOneBullet)`
  ${props =>
    props.active &&
    css`
      border: 3px solid #00b8c5;
      height: 12px;
      width: 12px;
    `}
`

const IndicatorOneText = styled(Link)`
  font-size: ${rem(11)}rem;
  text-align: center;
  color: ${props => (props.active ? "#00b8c5" : "#000000")};
  opacity: ${props => (props.active ? "1" : "0.4")};
  font-weight: ${props => (props.active ? "900" : "400")};

  &:hover {
    color: ${props => (props.active ? "#00b8c5" : "#000000")};
  }

  @media screen and (min-width: 768px) {
    font-size: ${rem(14)}rem;
  }
`
const IndicatorTwoText = styled(IndicatorOneText)`
  color: ${props => (props.active ? "#00b8c5" : "#000000")};
  opacity: ${props => (props.active ? "1" : "0.4")};

  &:hover {
    color: ${props => (props.active ? "#00b8c5" : "#000000")};
  }
`
const IndicatorThreeText = styled(IndicatorOneText)`
  color: ${props => (props.active ? "#00b8c5" : "#000000")};
  opacity: ${props => (props.active ? "1" : "0.4")};

  &:hover {
    color: ${props => (props.active ? "#00b8c5" : "#000000")};
  }
`
const IndicatorFourText = styled(IndicatorOneText)`
  color: ${props => (props.active ? "#00b8c5" : "#000000")};
  opacity: ${props => (props.active ? "1" : "0.4")};

  &:hover {
    color: ${props => (props.active ? "#00b8c5" : "#000000")};
  }
`
const CenterNavLinksBox = styled.div`
  margin: 0px auto;
`

const CenterNavLinks = styled.a`
  color: ${props => (props.active ? "#00B8C5 !important" : "#000 !important")};
  font-style: normal;
  font-weight: ${props => (props.active ? "600" : "300")};
  font-size: 15px;
  line-height: 18px;
  margin-right: ${rem(30)}rem;
  position: relative;
  font-weight: 600;

  &:hover,
  &:active {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    color: #00b8c5 !important;
  }
`

const DropdownContainer = styled.span`
  position: relative;
  margin: 0px 15px 0px 0;
  span {
    margin: 4px 0px 0 0;
    padding: 0 0 0 4px;
    position: absolute;
  }
`

const Dropdown = styled.div`
  position: absolute;
  background: white;
  top: 30px;
  left: -10px;
  border-radius: 6px;
  z-index: 20;
  padding: 5px 0;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  a {
    color: #4a4a4a;
  }
`

const DropdownItem = styled.div`
  width: 200px;
  padding: 0px 15px;
  &:hover {
    background: #f2f2f2;
  }
  p {
    font-size: 14px;
    cursor: pointer;
    padding: 6px 0px;
    border-bottom: ${props => (props.border ? "1px solid #f2f2f2" : "none")};
  }
`

export const TaskClientHeader = () => {
  const [isClient, setIsClient] = useState(false)
  const deviceType = useDeviceType()
  const [isHeaderExpanded, setHeaderExpanded] = useState(false)
  const openHeader = () => setHeaderExpanded(true)
  const closeHeader = () => setHeaderExpanded(false)

  useEffect(() => {
    setIsClient(true)
  }, [])

  return (
    <>
      {deviceType === DEVICE_TYPE_DESKTOP && (
        <TaskStickyHeader>
          <Wrapper
            onMouseLeave={closeHeader}
            className={`${isHeaderExpanded ? "expanded" : ""}`}
          >
            <DefaultContainer>
              <Topbar>
                <Logo />
                <HorizontalNavList>
                  {/*<HorizontalNavListItem>*/}
                  {/*  <Button type="link" onMouseEnter={openHeader}>*/}
                  {/*    Learn more*/}
                  {/*  </Button>*/}
                  {/*</HorizontalNavListItem>*/}
                  <HorizontalNavListItem>
                    <Link to="/guild">
                      <Button type="link" onMouseEnter={closeHeader}>
                        Developer Guild
                      </Button>
                    </Link>
                  </HorizontalNavListItem>
                  <HorizontalNavListItem>
                    <a
                      href={
                        isClient && !window.location.pathname.includes("earn")
                          ? "https://tasks.archimydes.io/login-page"
                          : "https://earn.archimydes.dev/login"
                      }
                      target="_blank"
                    >
                      <Button type="link" onMouseEnter={closeHeader}>
                        Login
                      </Button>
                    </a>
                  </HorizontalNavListItem>
                  <HorizontalNavListSeparator></HorizontalNavListSeparator>
                  <HorizontalNavListItem>
                    <Link to="/signup">
                      <Button type="solid" onMouseEnter={closeHeader}>
                        Get Started
                      </Button>
                    </Link>
                  </HorizontalNavListItem>
                </HorizontalNavList>
              </Topbar>
            </DefaultContainer>
            <HeaderExpansion className={`${isHeaderExpanded ? "active" : ""}`}>
              <DefaultContainer>
                <HorizontalNavList>
                  <HorizontalNavListItemSpaced>
                    <AnchorLink to="/how-it-works">
                      <Button type="link">Methodology</Button>
                    </AnchorLink>
                  </HorizontalNavListItemSpaced>
                  <HorizontalNavListItemSpaced>
                    <Link to="/about-us">
                      <Button type="link">About Us</Button>
                    </Link>
                  </HorizontalNavListItemSpaced>
                  <HorizontalNavListItemSpaced>
                    <Link to="/case-study/clarus">
                      <Button type="link">Case Studies</Button>
                    </Link>
                  </HorizontalNavListItemSpaced>
                  <HorizontalNavListItemSpaced>
                    <Link to="/comparison">
                      <Button type="link">Plan</Button>
                    </Link>
                  </HorizontalNavListItemSpaced>
                  <HorizontalNavListItemSpaced>
                    <Link to="/compare">
                      <Button type="link">Compare</Button>
                    </Link>
                  </HorizontalNavListItemSpaced>
                </HorizontalNavList>
              </DefaultContainer>
            </HeaderExpansion>
          </Wrapper>
        </TaskStickyHeader>
      )}
      {deviceType !== DEVICE_TYPE_DESKTOP && (
        <DefaultContainer>
          <Topbar>
            <Logo />
            <ResponsiveMenu>
              <Link to="/signup">
                <strong>Get a demo</strong>
              </Link>
              <Link to="/guild">Developer Guild</Link>
              <Link to="/how-it-works">Methodology</Link>
              <Link to="/about-us">About Us</Link>
              <Link to="/case-study/clarus">Case Studies</Link>
              <Link to="/comparison">Plan</Link>
              <Link to="/compare">Compare</Link>
              <a
                href={
                  isClient && !window.location.pathname.includes("earn")
                    ? "https://tasks.archimydes.io/login-page"
                    : "https://earn.archimydes.dev/login"
                }
                target="_blank"
              >
                Login
              </a>
            </ResponsiveMenu>
          </Topbar>
        </DefaultContainer>
      )}
    </>
  )
}

export const LandingPageHeader = ({ dark }) => {
  const { pathname } = useLocation()
  const [isClient, setIsClient] = useState(false)
  const typeformRefHeader = useRef()
  const onClickTypeform = () => {
    typeformRefHeader.current && typeformRefHeader.current.typeform.open()
  }
  const deviceType = useDeviceType()
  const [isHeaderExpanded, setHeaderExpanded] = useState(false)
  const openHeader = () => setHeaderExpanded(true)
  const closeHeader = () => setHeaderExpanded(false)
  const [isDropdownVisible, setDropdownVisible] = React.useState(false)
  const [isGuildDropdown, setGuildDropdown] = React.useState(false)
  const [isFourthActVisible, setFourthActVisible] = React.useState(false)

  useEffect(() => {
    setIsClient(true)
  }, [])

  return (
    <>
      {deviceType === DEVICE_TYPE_DESKTOP && (
        <React.Fragment>
          <NewTaskStickyHeader dark={dark}>
            <Wrapper
              onMouseLeave={closeHeader}
              className={`${isHeaderExpanded ? "expanded" : ""}`}
            >
              <ReactTypeformEmbed
                popup
                autoOpen={false}
                hideHeaders
                hideFooter
                style={{ top: 100 }}
                url="https://jfox813621.typeform.com/to/ygspaPkA"
                ref={typeformRefHeader}
              />
              <DefaultContainer>
                <NewTopbar>
                  <Logo isHomepage logo={dark ? "light" : "dark"} />
                  <HorizontalNavList className="full-width">
                    {isClient && (
                      <CenterNavLinksBox>
                        <CenterNavLinks href="/" active={pathname === "/"}>
                          Home
                        </CenterNavLinks>
                        <DropdownContainer>
                          <CenterNavLinks
                            active={
                              pathname.includes("guild") ||
                              pathname.includes("apprenticeship")
                            }
                            onClick={() => setGuildDropdown(e => !e)}
                          >
                            Guild
                            <span>
                              {isGuildDropdown ? (
                                <IoIosArrowUp />
                              ) : (
                                <IoIosArrowDown />
                              )}
                            </span>
                          </CenterNavLinks>
                          {isGuildDropdown && (
                            <Dropdown>
                              <DropdownItem
                                border={true}
                                onClick={() => {
                                  setGuildDropdown(false)
                                  navigate("/guild")
                                }}
                              >
                                <p>About</p>
                              </DropdownItem>
                              <DropdownItem
                                border={true}
                                onClick={() => {
                                  setGuildDropdown(false)
                                  navigate("/apprenticeship")
                                }}
                              >
                                <p>Apprenticeship</p>
                              </DropdownItem>
                              <a
                                href="https://profile.archimydes.dev/"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <DropdownItem>
                                  <p>Portfolio</p>
                                </DropdownItem>
                              </a>
                            </Dropdown>
                          )}
                        </DropdownContainer>
                        {/* <CenterNavLinks
                          href="/code"
                          active={pathname.includes("code")}
                        >
                          Code
                        </CenterNavLinks>
                        <CenterNavLinks
                          href="/apprenticeship"
                          active={pathname.includes("apprenticeship")}
                        >
                          Apprenticeship
                        </CenterNavLinks> */}
                        <DropdownContainer>
                          <CenterNavLinks
                            active={pathname.includes("offerings")}
                            onClick={() => setDropdownVisible(e => !e)}
                          >
                            Offerings
                            <span>
                              {isDropdownVisible ? (
                                <IoIosArrowUp />
                              ) : (
                                <IoIosArrowDown />
                              )}
                            </span>
                          </CenterNavLinks>
                          {isDropdownVisible && (
                            <Dropdown>
                              <DropdownItem
                                border={true}
                                onClick={() => {
                                  setDropdownVisible(false)
                                  navigate("/offerings/product-development")
                                }}
                              >
                                <p>Product Development</p>
                              </DropdownItem>
                              <DropdownItem
                                border={true}
                                onClick={() => {
                                  setDropdownVisible(false)
                                  pathname.includes("offerings")
                                  navigate("/offerings/security")
                                }}
                              >
                                <p>Security</p>
                              </DropdownItem>
                              <DropdownItem
                                onClick={() => {
                                  setDropdownVisible(false)
                                  pathname.includes("offerings")
                                  navigate("/offerings/diligence")
                                }}
                              >
                                <p>Due Diligence</p>
                              </DropdownItem>
                            </Dropdown>
                          )}
                        </DropdownContainer>
                        <DropdownContainer>
                          <CenterNavLinks
                            onClick={() => setFourthActVisible(e => !e)}
                          >
                            Fourth Act
                            <span>
                              {isFourthActVisible ? (
                                <IoIosArrowUp />
                              ) : (
                                <IoIosArrowDown />
                              )}
                            </span>
                          </CenterNavLinks>
                          {isFourthActVisible && (
                            <Dropdown>
                              <a
                                href="https://archimydes.dev/fourthact/"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <DropdownItem border={true}>
                                  <p>Home</p>
                                </DropdownItem>
                              </a>
                              <a
                                href="https://archimydes.dev/fourthact/events"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <DropdownItem border={true}>
                                  <p>Masterminds</p>
                                </DropdownItem>
                              </a>
                              <a
                                href="https://archimydes.dev/fourthact/podcast"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <DropdownItem>
                                  <p>Podcasts</p>
                                </DropdownItem>
                              </a>
                            </Dropdown>
                          )}
                        </DropdownContainer>
                        <CenterNavLinks
                          href="/health-tech"
                          active={pathname === "/health-tech"}
                        >
                          Healthtech & Lifescience
                        </CenterNavLinks>
                        {/* {!pathname.includes("apprenticeship") && (
                          <CenterNavLinks>
                            <HeaderLinksExternalLinks
                              dark={dark}
                              href={
                                pathname === "/"
                                  ? "https://tasks.archimydes.io/login-page"
                                  : "https://earn.archimydes.dev/login"
                              }
                              target="_blank"
                            >
                              Login
                            </HeaderLinksExternalLinks>
                          </CenterNavLinks>
                        )} */}
                      </CenterNavLinksBox>
                    )}
                  </HorizontalNavList>
                  <div
                    style={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      minWidth: "250px",
                    }}
                  >
                    {(pathname === "/" || pathname.includes("prototyping")) && (
                      <PageButton onClick={onClickTypeform} type="solid">
                        Request Code
                        <HiChevronRight size={22} />
                      </PageButton>
                    )}
                    {/* {pathname.includes("guild") && (
                      <Link to="/devjoin">
                        <HeaderButton type="solid">
                          Join Guild Waitlist
                          <HiChevronRight size={22} />
                        </HeaderButton>
                      </Link>
                    )} */}
                    {pathname.includes("apprenticeship") && (
                      <div
                        onClick={() => {
                          if (isClient) {
                            const scrollTo = document.body.scrollHeight - 800
                            window.scrollTo(0, scrollTo)
                          }
                        }}
                      >
                        <HeaderButton type="solid">
                          Apply Now
                          <HiChevronRight size={22} />
                        </HeaderButton>
                      </div>
                    )}
                  </div>
                </NewTopbar>
              </DefaultContainer>
            </Wrapper>
          </NewTaskStickyHeader>
        </React.Fragment>
      )}
      {deviceType !== DEVICE_TYPE_DESKTOP && (
        <LanMobileHeader dark={dark}>
          <DefaultContainer>
            <Topbar>
              <ReactTypeformEmbed
                popup
                autoOpen={false}
                hideHeaders
                hideFooter
                style={{ top: 100 }}
                url="https://jfox813621.typeform.com/to/ygspaPkA"
                ref={typeformRefHeader}
              />
              <Logo logo={dark ? "light" : "dark"} />
              <NewResponsiveMenu
                dark={dark}
                open={isDropdownVisible || isFourthActVisible}
              >
                <Link to="/">Home</Link>
                {pathname.includes("enhancements") ||
                pathname.includes("prototyping") ? (
                  <a onClick={onClickTypeform}>
                    <strong>Request Code</strong>
                  </a>
                ) : (
                  <>
                    {pathname.includes("code") && (
                      <a
                        onClick={() =>
                          openPopupWidget({
                            url: "https://calendly.com/archimydes/30min",
                          })
                        }
                      >
                        <strong>Schedule Session</strong>
                      </a>
                    )}
                  </>
                )}
                {/* <Link to="/code">Code as a Service</Link> */}
                <div>
                  <a
                    className={isGuildDropdown ? "parent-link" : ""}
                    onClick={() => setGuildDropdown(e => !e)}
                  >
                    Guild
                  </a>
                  {isGuildDropdown && (
                    <div className="sub-menu">
                      <a
                        onClick={() => {
                          setGuildDropdown(false)
                          navigate("/guild")
                        }}
                      >
                        About
                      </a>
                      <a
                        onClick={() => {
                          setGuildDropdown(false)
                          navigate("/apprenticeship")
                        }}
                      >
                        Apprenticeship
                      </a>
                      <a
                        href="https://profile.archimydes.dev/ "
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Portfolio
                      </a>
                    </div>
                  )}
                </div>
                {pathname.includes("guild") && (
                  <Link to="/devjoin">
                    <strong>Join Guild Waitlist</strong>
                  </Link>
                )}
                {/* Offerings */}
                <div>
                  <a
                    className={isDropdownVisible ? "parent-link" : ""}
                    onClick={() => setDropdownVisible(e => !e)}
                  >
                    Offerings
                  </a>
                  {isDropdownVisible && (
                    <div className="sub-menu">
                      <a
                        onClick={() => {
                          setDropdownVisible(false)
                          navigate("/offerings/product-development")
                        }}
                      >
                        Product Development
                      </a>
                      <a
                        onClick={() => {
                          setDropdownVisible(false)
                          pathname.includes("offerings")
                          navigate("/offerings/security")
                        }}
                      >
                        Security
                      </a>
                      <a
                        onClick={() => {
                          setDropdownVisible(false)
                          pathname.includes("offerings")
                          navigate("/offerings/diligence")
                        }}
                      >
                        Due Diligence
                      </a>
                    </div>
                  )}
                </div>
                {/* Fourth act */}
                <div>
                  <a
                    className={isFourthActVisible ? "parent-link" : ""}
                    onClick={() => setFourthActVisible(e => !e)}
                  >
                    FourthAct
                  </a>
                  {isFourthActVisible && (
                    <div className="sub-menu">
                      <a
                        href="https://archimydes.dev/fourthact/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Home
                      </a>
                      <a
                        href="https://archimydes.dev/fourthact/events"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Masterminds
                      </a>
                      <a
                        href="https://archimydes.dev/fourthact/podcast"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Podcasts
                      </a>
                    </div>
                  )}
                </div>
                <Link to="/health-tech">Healthtech & Lifescience</Link>
                {/* <a
                  href={
                    pathname === "/"
                      ? "https://tasks.archimydes.io/login-page"
                      : "https://earn.archimydes.dev/login"
                  }
                  target="_blank"
                >
                  Login
                </a> */}
              </NewResponsiveMenu>
            </Topbar>
          </DefaultContainer>
        </LanMobileHeader>
      )}
    </>
  )
}

export const NewLandingPageHeader = ({ dark }) => {
  const { pathname } = useLocation()
  const [isClient, setIsClient] = useState(false)
  const typeformRefHeader = useRef()
  const onClickTypeform = () => {
    typeformRefHeader.current && typeformRefHeader.current.typeform.open()
  }
  const deviceType = useDeviceType()
  const [isHeaderExpanded, setHeaderExpanded] = useState(false)
  const openHeader = () => setHeaderExpanded(true)
  const closeHeader = () => setHeaderExpanded(false)
  const [isDropdownVisible, setDropdownVisible] = React.useState(false)
  const [isGuildDropdown, setGuildDropdown] = React.useState(false)
  const [isFourthActVisible, setFourthActVisible] = React.useState(false)

  useEffect(() => {
    setIsClient(true)
  }, [])

  return (
    <>
      {deviceType === DEVICE_TYPE_DESKTOP && (
        <React.Fragment>
          <NewTaskStickyHeader dark={dark}>
            <Wrapper
              onMouseLeave={closeHeader}
              className={`${isHeaderExpanded ? "expanded" : ""}`}
            >
              <DefaultContainer>
                <NewTopbar>
                  <Logo isHomepage logo={dark ? "light" : "dark"} />
                  <HorizontalNavList className="full-width">
                    {/* <HorizontalNavListItem>
                    <Button type="link" onMouseEnter={openHeader}>Learn more</Button>
                  </HorizontalNavListItem> */}
                    {isClient && (
                      <CenterNavLinksBox>
                        <CenterNavLinks href="/" active={pathname === "/"}>
                          Home
                        </CenterNavLinks>
                        <DropdownContainer>
                          <CenterNavLinks
                            active={
                              pathname.includes("guild") ||
                              pathname.includes("apprenticeship")
                            }
                            onClick={() => setGuildDropdown(e => !e)}
                          >
                            Guild
                            <span>
                              {isGuildDropdown ? (
                                <IoIosArrowUp />
                              ) : (
                                <IoIosArrowDown />
                              )}
                            </span>
                          </CenterNavLinks>
                          {isGuildDropdown && (
                            <Dropdown>
                              <DropdownItem
                                border={true}
                                onClick={() => {
                                  setGuildDropdown(false)
                                  navigate("/guild")
                                }}
                              >
                                <p>About</p>
                              </DropdownItem>
                              <DropdownItem
                                border={true}
                                onClick={() => {
                                  setGuildDropdown(false)
                                  navigate("/apprenticeship")
                                }}
                              >
                                <p>Apprenticeship</p>
                              </DropdownItem>
                              <a
                                href="https://profile.archimydes.dev/ "
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <DropdownItem>
                                  <p>Portfolio</p>
                                </DropdownItem>
                              </a>
                            </Dropdown>
                          )}
                        </DropdownContainer>
                        <DropdownContainer>
                          <CenterNavLinks
                            active={pathname.includes("offerings")}
                            onClick={() => setDropdownVisible(e => !e)}
                          >
                            Offerings
                            <span>
                              {isDropdownVisible ? (
                                <IoIosArrowUp />
                              ) : (
                                <IoIosArrowDown />
                              )}
                            </span>
                          </CenterNavLinks>
                          {isDropdownVisible && (
                            <Dropdown>
                              <DropdownItem
                                border={true}
                                onClick={() => {
                                  setDropdownVisible(false)
                                  navigate("/offerings/product-development")
                                }}
                              >
                                <p>Product Development</p>
                              </DropdownItem>
                              <DropdownItem
                                border={true}
                                onClick={() => {
                                  setDropdownVisible(false)
                                  pathname.includes("offerings")
                                  navigate("/offerings/security")
                                }}
                              >
                                <p>Security</p>
                              </DropdownItem>
                              <DropdownItem
                                onClick={() => {
                                  setDropdownVisible(false)
                                  pathname.includes("offerings")
                                  navigate("/offerings/diligence")
                                }}
                              >
                                <p>Due Diligence</p>
                              </DropdownItem>
                            </Dropdown>
                          )}
                        </DropdownContainer>
                        <DropdownContainer>
                          <CenterNavLinks
                            onClick={() => setFourthActVisible(e => !e)}
                          >
                            Fourth Act
                            <span>
                              {isFourthActVisible ? (
                                <IoIosArrowUp />
                              ) : (
                                <IoIosArrowDown />
                              )}
                            </span>
                          </CenterNavLinks>
                          {isFourthActVisible && (
                            <Dropdown>
                              <a
                                href="https://archimydes.dev/fourthact/"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <DropdownItem border={true}>
                                  <p>Home</p>
                                </DropdownItem>
                              </a>
                              <a
                                href="https://archimydes.dev/fourthact/events"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <DropdownItem border={true}>
                                  <p>Masterminds</p>
                                </DropdownItem>
                              </a>
                              <a
                                href="https://archimydes.dev/fourthact/podcast"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <DropdownItem>
                                  <p>Podcasts</p>
                                </DropdownItem>
                              </a>
                            </Dropdown>
                          )}
                        </DropdownContainer>
                        <CenterNavLinks
                          href="/health-tech"
                          active={pathname === "/health-tech"}
                        >
                          Healthtech & Lifescience
                        </CenterNavLinks>
                      </CenterNavLinksBox>
                    )}
                    {/* {pathname.includes("guild") && (
                      <HorizontalNavListItem>
                        <div>
                          <Link to="/devjoin">
                            <HeaderButton type="solid">
                              Join Guild Waitlist
                              <HiChevronRight size={22} />
                            </HeaderButton>
                          </Link>
                        </div>
                      </HorizontalNavListItem>
                    )} */}
                  </HorizontalNavList>
                  {/* {(pathname === "/" || pathname.includes("offerings")) && (
                    <div style={{ flex: 1, minWidth: "250px" }} />
                  )} */}
                </NewTopbar>
              </DefaultContainer>
            </Wrapper>
          </NewTaskStickyHeader>
        </React.Fragment>
      )}
      {deviceType !== DEVICE_TYPE_DESKTOP && (
        <LanMobileHeader dark={dark}>
          <DefaultContainer>
            <Topbar>
              <ReactTypeformEmbed
                popup
                autoOpen={false}
                hideHeaders
                hideFooter
                style={{ top: 10 }}
                url="https://jfox813621.typeform.com/to/ygspaPkA"
                ref={typeformRefHeader}
              />
              <Logo logo={dark ? "light" : "dark"} />
              <NewResponsiveMenu
                dark={dark}
                open={
                  isDropdownVisible || isFourthActVisible || isGuildDropdown
                }
              >
                <Link to="/">Home</Link>
                {pathname.includes("enhancements") ||
                pathname.includes("prototyping") ? (
                  <a onClick={onClickTypeform}>
                    <strong>Request Code</strong>
                  </a>
                ) : (
                  <>
                    {pathname.includes("code") && (
                      <a
                        onClick={() =>
                          openPopupWidget({
                            url: "https://calendly.com/archimydes/30min",
                          })
                        }
                      >
                        <strong>Schedule Session</strong>
                      </a>
                    )}
                  </>
                )}
                {pathname.includes("guild") && (
                  <Link to="/devjoin">
                    <strong>Join Guild Waitlist</strong>
                  </Link>
                )}
                {/* <Link to="/code">Code as a Service</Link> */}

                <div>
                  <a
                    className={isGuildDropdown ? "parent-link" : ""}
                    onClick={() => setGuildDropdown(e => !e)}
                  >
                    Guild
                  </a>
                  {isGuildDropdown && (
                    <div className="sub-menu">
                      <a
                        onClick={() => {
                          setGuildDropdown(false)
                          navigate("/guild")
                        }}
                      >
                        About
                      </a>
                      <a
                        onClick={() => {
                          setGuildDropdown(false)
                          navigate("/apprenticeship")
                        }}
                      >
                        Apprenticeship
                      </a>
                      <a
                        href="https://profile.archimydes.dev/ "
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Portfolio
                      </a>
                    </div>
                  )}
                </div>
                {pathname.includes("guild") ||
                  (pathname.includes("apprenticeship") && (
                    <Link to="/code">Code as a Service</Link>
                  ))}
                {/* Offerings */}
                <div>
                  <a
                    className={isDropdownVisible ? "parent-link" : ""}
                    onClick={() => setDropdownVisible(e => !e)}
                  >
                    Offerings
                  </a>
                  {isDropdownVisible && (
                    <div className="sub-menu">
                      <a
                        onClick={() => {
                          setDropdownVisible(false)
                          navigate("/offerings/product-development")
                        }}
                      >
                        Product Development
                      </a>
                      <a
                        onClick={() => {
                          setDropdownVisible(false)
                          pathname.includes("offerings")
                          navigate("/offerings/security")
                        }}
                      >
                        Security
                      </a>
                      <a
                        onClick={() => {
                          setDropdownVisible(false)
                          pathname.includes("offerings")
                          navigate("/offerings/diligence")
                        }}
                      >
                        Due Diligence
                      </a>
                    </div>
                  )}
                </div>
                {/* Fourth act */}
                <div>
                  <a
                    className={isFourthActVisible ? "parent-link" : ""}
                    onClick={() => setFourthActVisible(e => !e)}
                  >
                    FourthAct
                  </a>
                  {isFourthActVisible && (
                    <div className="sub-menu">
                      <a
                        href="https://archimydes.dev/fourthact/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Home
                      </a>
                      <a
                        href="https://archimydes.dev/fourthact/events"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Masterminds
                      </a>
                      <a
                        href="https://archimydes.dev/fourthact/podcast"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Podcasts
                      </a>
                    </div>
                  )}
                </div>
                <Link to="/health-tech">Healthtech & Lifescience</Link>
                {/* <a
                  href={
                    pathname === "/"
                      ? "https://tasks.archimydes.io/login-page"
                      : "https://earn.archimydes.dev/login"
                  }
                  target="_blank"
                >
                  Login
                </a> */}
              </NewResponsiveMenu>
            </Topbar>
          </DefaultContainer>
        </LanMobileHeader>
      )}
    </>
  )
}

export const ApprenticeshipHeader = ({ dark }) => {
  const [isClient, setIsClient] = useState(false)
  const typeformRefHeader = useRef()
  const onClickTypeform = () => {
    typeformRefHeader.current && typeformRefHeader.current.typeform.open()
  }
  const deviceType = useDeviceType()
  const [isHeaderExpanded, setHeaderExpanded] = useState(false)
  const openHeader = () => setHeaderExpanded(true)
  const closeHeader = () => setHeaderExpanded(false)

  useEffect(() => {
    setIsClient(true)
  }, [])

  return (
    <>
      {deviceType === DEVICE_TYPE_DESKTOP && (
        <React.Fragment>
          <NewTaskStickyHeader dark={dark}>
            <Wrapper
              onMouseLeave={closeHeader}
              className={`${isHeaderExpanded ? "expanded" : ""}`}
            >
              <ReactTypeformEmbed
                popup
                autoOpen={false}
                hideHeaders
                hideFooter
                style={{ top: 100 }}
                url="https://jfox813621.typeform.com/to/ygspaPkA"
                ref={typeformRefHeader}
              />
              <DefaultContainer>
                <NewTopbar>
                  <Logo logo={dark ? "light" : "dark"} />
                  <HorizontalNavList>
                    <HorizontalNavListItem>
                      <ApprenticeHeaderLinks dark={dark} to="/">
                        Home
                      </ApprenticeHeaderLinks>
                      <ApprenticeHeaderLinks dark={dark} to="/code">
                        Code
                      </ApprenticeHeaderLinks>
                      <ApprenticeHeaderLinks dark={dark} to="/guild">
                        Guild
                      </ApprenticeHeaderLinks>
                      <ApprenticeHeaderLinks
                        dark={dark}
                        to={"/apprenticeship"}
                        active={
                          isClient &&
                          window.location.pathname.includes("apprenticeship")
                            ? "true"
                            : "false"
                        }
                      >
                        Apprenticeship
                      </ApprenticeHeaderLinks>
                    </HorizontalNavListItem>
                  </HorizontalNavList>
                  <HorizontalNavList>
                    <HorizontalNavListItem>
                      <div
                        onClick={() => {
                          if (isClient) {
                            const scrollTo = document.body.scrollHeight - 900
                            window.scrollTo(0, scrollTo)
                          }
                        }}
                      >
                        <PageButton type="solid">
                          {(isClient &&
                            window.location.pathname.includes(
                              "/apprenticeship/apprentice"
                            )) ||
                          (isClient &&
                            window.location.pathname === "/apprenticeship")
                            ? "Apply Now"
                            : `Let's go`}
                          <HiChevronRight size={22} />
                        </PageButton>
                      </div>
                    </HorizontalNavListItem>
                  </HorizontalNavList>
                </NewTopbar>
              </DefaultContainer>
            </Wrapper>
          </NewTaskStickyHeader>
        </React.Fragment>
      )}
      {deviceType !== DEVICE_TYPE_DESKTOP && (
        <LanMobileHeader dark={dark}>
          <DefaultContainer>
            <Topbar>
              <ReactTypeformEmbed
                popup
                autoOpen={false}
                hideHeaders
                hideFooter
                style={{ top: 100 }}
                url="https://jfox813621.typeform.com/to/ygspaPkA"
                ref={typeformRefHeader}
              />
              <Logo logo={dark ? "light" : "dark"} />
              <NewResponsiveMenu dark={dark}>
                <Link to="/apprenticeship/apprentice">Apprentice</Link>
                <Link to="/apprenticeship/startups">Startups</Link>
                <Link to="/apprenticeship/social-enterprise">
                  Social Enterprise
                </Link>
                <Link to="/apprenticeship/mentor">Mentors</Link>
              </NewResponsiveMenu>
            </Topbar>
          </DefaultContainer>
        </LanMobileHeader>
      )}
      {deviceType === DEVICE_TYPE_DESKTOP &&
        isClient &&
        window.location.pathname.includes("apprenticeship") && (
          <HeaderIndicator>
            <IndicatorContainer>
              <HorizonatalLine />
              <IndicatorOne to="/apprenticeship/apprentice">
                <IndicatorOneBullet
                  active={
                    isClient &&
                    window.location.pathname.includes(
                      "apprenticeship/apprentice"
                    )
                  }
                />
                <IndicatorOneText
                  active={
                    isClient &&
                    window.location.pathname.includes(
                      "apprenticeship/apprentice"
                    )
                  }
                >
                  Apprentice
                </IndicatorOneText>
              </IndicatorOne>
              <IndicatorTwo to="/apprenticeship/startups">
                <IndicatorTwoBullet
                  active={
                    isClient && window.location.pathname.includes("startups")
                  }
                />
                <IndicatorTwoText
                  active={
                    isClient && window.location.pathname.includes("startups")
                  }
                >
                  Startups
                </IndicatorTwoText>
              </IndicatorTwo>
              <IndicatorThree to="/apprenticeship/social-enterprise">
                <IndicatorThreeBullet
                  active={
                    isClient &&
                    window.location.pathname.includes("social-enterprise")
                  }
                />
                <IndicatorThreeText
                  active={
                    isClient &&
                    window.location.pathname.includes("social-enterprise")
                  }
                >
                  Social Enterprise
                </IndicatorThreeText>
              </IndicatorThree>
              <IndicatorFour to="/apprenticeship/mentor">
                <IndicatorFourBullet
                  active={
                    isClient && window.location.pathname.includes("mentor")
                  }
                />
                <IndicatorFourText
                  active={
                    isClient && window.location.pathname.includes("mentor")
                  }
                >
                  Mentors
                </IndicatorFourText>
              </IndicatorFour>
            </IndicatorContainer>
          </HeaderIndicator>
        )}
    </>
  )
}

export const ClientHeader = () => {
  const typeformRefHeader = useRef()
  const onClickTypeform = () => {
    typeformRefHeader.current && typeformRefHeader.current.typeform.open()
  }
  const deviceType = useDeviceType()
  const [isHeaderExpanded, setHeaderExpanded] = useState(false)
  const openHeader = () => setHeaderExpanded(true)
  const closeHeader = () => setHeaderExpanded(false)

  return (
    <>
      {deviceType === DEVICE_TYPE_DESKTOP && (
        <Wrapper
          onMouseLeave={closeHeader}
          className={`${isHeaderExpanded ? "expanded" : ""}`}
        >
          <ReactTypeformEmbed
            popup
            autoOpen={false}
            hideHeaders
            hideFooter
            style={{ top: 100 }}
            url="https://jfox813621.typeform.com/to/ygspaPkA"
            ref={typeformRefHeader}
          />
          <DefaultContainer>
            <Topbar>
              <Logo />
              <HorizontalNavList>
                {/*<HorizontalNavListItem>*/}
                {/*  <Button type="link" onMouseEnter={openHeader}>*/}
                {/*    Learn more*/}
                {/*  </Button>*/}
                {/*</HorizontalNavListItem>*/}
                <HorizontalNavListItem>
                  <Link to="/guild">
                    <Button type="link" onMouseEnter={closeHeader}>
                      Developer Guild
                    </Button>
                  </Link>
                </HorizontalNavListItem>
                <HorizontalNavListItem>
                  <a
                    href="https://tasks.archimydes.io/login-page"
                    target="_blank"
                  >
                    <Button type="link" onMouseEnter={closeHeader}>
                      Login
                    </Button>
                  </a>
                </HorizontalNavListItem>
                <HorizontalNavListSeparator></HorizontalNavListSeparator>
                <HorizontalNavListItem>
                  <div onClick={onClickTypeform}>
                    <PageButton type="solid">
                      Request Code
                      <HiChevronRight size={22} />
                    </PageButton>
                  </div>
                </HorizontalNavListItem>
              </HorizontalNavList>
            </Topbar>
          </DefaultContainer>
          <HeaderExpansion className={`${isHeaderExpanded ? "active" : ""}`}>
            <DefaultContainer>
              <HorizontalNavList>
                <HorizontalNavListItemSpaced>
                  <AnchorLink to="/how-it-works">
                    <Button type="link">Methodology</Button>
                  </AnchorLink>
                </HorizontalNavListItemSpaced>
                <HorizontalNavListItemSpaced>
                  <Link to="/about-us">
                    <Button type="link">About Us</Button>
                  </Link>
                </HorizontalNavListItemSpaced>
                <HorizontalNavListItemSpaced>
                  <Link to="/case-study/clarus">
                    <Button type="link">Case Studies</Button>
                  </Link>
                </HorizontalNavListItemSpaced>
                <HorizontalNavListItemSpaced>
                  <Link to="/comparison">
                    <Button type="link">Plan</Button>
                  </Link>
                </HorizontalNavListItemSpaced>
                <HorizontalNavListItemSpaced>
                  <Link to="/compare">
                    <Button type="link">Compare</Button>
                  </Link>
                </HorizontalNavListItemSpaced>
              </HorizontalNavList>
            </DefaultContainer>
          </HeaderExpansion>
        </Wrapper>
      )}
      {deviceType !== DEVICE_TYPE_DESKTOP && (
        <DefaultContainer>
          <Topbar>
            <ReactTypeformEmbed
              popup
              autoOpen={false}
              hideHeaders
              hideFooter
              style={{ top: 100 }}
              url="https://jfox813621.typeform.com/to/ygspaPkA"
              ref={typeformRefHeader}
            />
            <Logo />
            <NewResponsiveMenu>
              <a onClick={onClickTypeform}>
                <strong>Request Code</strong>
              </a>
              <Link to="/guild">Developer Guild</Link>
              <a href="https://tasks.archimydes.io/login-page" target="_blank">
                Login
              </a>
            </NewResponsiveMenu>
          </Topbar>
        </DefaultContainer>
      )}
    </>
  )
}

export const GoogleHeader = () => {
  return (
    <StickyHeader>
      <Container>
        <h1 className="sr-only">Archimydes</h1>
        <Brand to="/tasks">
          <SrOnly>Archimydes</SrOnly>
        </Brand>
        <HorizontalNavList>
          <HorizontalNavListItem>
            <Link to="/signup">
              <LearnMoreButton type="solid">Get Started</LearnMoreButton>
            </Link>
          </HorizontalNavListItem>
        </HorizontalNavList>
      </Container>
    </StickyHeader>
  )
}

export const SEOHeader = () => {
  const deviceType = useDeviceType()
  return (
    <>
      {deviceType !== DEVICE_TYPE_MOBILE && (
        <Topbar>
          <Logo />
          <HorizontalNavList>
            <HorizontalNavListItem>
              <Link to="/signup">
                <Button type="solid">Get Started</Button>
              </Link>
            </HorizontalNavListItem>
          </HorizontalNavList>
        </Topbar>
      )}
      {deviceType === DEVICE_TYPE_MOBILE && (
        <Topbar>
          <Logo />
          <ResponsiveMenu>
            <Link to="/signup">Get Started</Link>
          </ResponsiveMenu>
        </Topbar>
      )}
    </>
  )
}

const Header = () => {
  const deviceType = useDeviceType()
  return (
    <>
      {deviceType !== DEVICE_TYPE_MOBILE && (
        <Topbar>
          <Logo />
          <HorizontalNavList>
            <HorizontalNavListItemMidSpace>
              <Link to="/tasks">
                <Button type="link">Request a feature</Button>
              </Link>
            </HorizontalNavListItemMidSpace>
            <HorizontalNavListItemMidSpace>
              <a href="https://earn.archimydes.dev/login" target="_blank">
                <Button type="link">Login</Button>
              </a>
            </HorizontalNavListItemMidSpace>
            <HorizontalNavListItem>
              <div>
                <PageButton type="solid">
                  Request Code
                  <HiChevronRight size={22} />
                </PageButton>
              </div>
            </HorizontalNavListItem>
          </HorizontalNavList>
        </Topbar>
      )}
      {deviceType === DEVICE_TYPE_MOBILE && (
        <Topbar>
          <Logo />
          <ResponsiveMenu>
            <Link to="/tasks">Request a feature</Link>
            <a href="https://earn.archimydes.dev/login" target="_blank">
              Login
            </a>
            <Link to="/earn">Earn</Link>
            <Link to="/grow">Grow</Link>
          </ResponsiveMenu>
        </Topbar>
      )}
    </>
  )
}

export default Header
