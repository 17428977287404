import styled from "styled-components"
import { rem } from "../utils/styling"
import reactIcon from "../images/react.svg"
import nodeIcon from "../images/node.svg"
import world from "../images/world.png"

export const Banner = styled.div`
  background: ${props => props.theme.banner.backgroundMobile};
  box-sizing: border-box;
  color: ${props => props.theme.banner.color};
  overflow: hidden;
  position: relative;
  @media screen and (min-width: 1100px) {
    background: ${props => props.theme.banner.background};
  }
`

export const LandingBanner = styled(Banner)`
  background: #03162c;
  z-index: 10;
  padding: 30rem;
  @media screen and (min-width: 1100px) {
    background: #03162c;
    position: relative;
    width: 100vw;
  }
  @media screen and (max-width: 500px) {
  }
`

export const UnderlayBanner = styled.div`
  background-color: #fff;
  height: 100%;
  width: 100%;
  @media screen and (min-width: 1100px) {
  }
  @media screen and (max-width: 550px) {
  }
`

export const NewLandingBanner = styled.div`
  padding: 10rem 12rem 6rem 12rem;
  width: 100%;
  @media screen and (max-width: 1300px) {
    padding: 5rem 10rem 6rem 10rem;
  }
  @media screen and (max-width: 1100px) {
    padding: 3rem 2rem 4rem 2rem;
    margin-top: 0;
  }
  @media screen and (max-width: 500px) {
    padding: 6rem 1.5rem 2rem;
  }
`

export const BannerText = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: ${rem(16)}rem;
  line-height: 1.8;
  color: ${props => props.theme.banner.color};
  margin-top: ${rem(32)}rem;
  margin-bottom: ${rem(48)}rem;
  em {
    color: #00b8c5;
    font-style: normal;
  }
  @media screen and (min-width: 1100px) {
    margin-top: ${rem(24)}rem;
    font-size: ${rem(20)}rem;
    line-height: 1.6;
  }
`
export const BannerTitleSecondary = styled.h3`
  font-size: ${rem(24)}rem;
  padding-bottom: ${rem(18)}rem;
  position: relative;
  margin-bottom: ${rem(24)}rem;
  font-weight: bold;
  &::after {
    content: " ";
    width: ${rem(64)}rem;
    height: 3px;
    position: absolute;
    bottom: 0;
    display: block;
    background: #00b8c5;
    left: 50%;
    margin-left: ${rem(-32)}rem;
  }
  @media screen and (min-width: 1100px) {
    font-size: ${rem(32)}rem;
    padding-bottom: ${rem(25)}rem;
    &::after {
      left: 0;
      margin-left: 0;
    }
  }
`

export const BannerContent = styled.div`
  width: 100%;
  position: relative;
  padding-top: ${rem(20)}rem;
  padding-bottom: ${rem(90)}rem;
  justify-content: space-between;
  flex-direction: row;
  text-align: center;
  @media screen and (min-width: 1100px) {
    text-align: left;
    display: flex;
    padding-top: ${rem(80)}rem;
  }
`

export const LandingBannerContent = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: ${rem(120)}rem;
  @media screen and (min-width: 1100px) {
    margin-top: ${rem(10)}rem;
  }
  @media screen and (max-width: 550px) {
    margin-top: ${rem(30)}rem;
  }
`

export const BannerContentGoogle = styled.div`
  width: 100%;
  position: relative;
  padding-top: ${rem(70)}rem;
  padding-bottom: ${rem(90)}rem;
  justify-content: space-between;
  flex-direction: row;
  text-align: center;
  @media screen and (min-width: 1100px) {
    text-align: left;
    display: flex;
    padding-top: ${rem(150)}rem;
  }
`

export const BannerContentFull = styled.div`
  width: 100%;
  @media screen and (min-width: 1100px) {
    width: ${rem(661)}rem;
  }
`
export const BannerContentFullWidth = styled.div`
  width: 100%;
  @media screen and (min-width: 1100px) {
    width: 100%;
  }
`
export const BannerContentLeft = styled.div`
  margin-bottom: 5rem;
  @media screen and (min-width: 1100px) {
    width: ${rem(456)}rem;
    margin-bottom: 0;
  }
`

export const BannerContentRight = styled.div`
  @media screen and (min-width: 1100px) {
    width: ${rem(480)}rem;
  }
`

export const ReactIcon = styled.span`
  width: ${rem(55)}rem;
  height: ${rem(50)}rem;
  background-image: url(${reactIcon});
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  margin: 0 auto;
  margin-top: 2rem;
  @media screen and (min-width: 1100px) {
    margin: 0;
    display: block;
  }
`
export const NodeIcon = styled.span`
  width: ${rem(83)}rem;
  height: ${rem(51)}rem;
  background-image: url(${nodeIcon});
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  margin: 0 auto;
  margin-top: 2rem;
  @media screen and (min-width: 1100px) {
    margin: 0;
    display: block;
  }
`

export const SkillList = styled.div`
  font-size: ${rem(20)}rem;
  color: #ffffff;
  font-weight: 500;
  margin-top: ${rem(46)}rem;
  margin-bottom: ${rem(46)}rem;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  p {
    min-width: 100%;
  }
  @media screen and (min-width: 1100px) {
    flex-wrap: nowrap;
    p {
      min-width: initial;
    }

    > * {
      margin-right: ${rem(48)}rem;
    }
  }
`

export const WorldBackground = styled.div`
  display: none;
  @media screen and (min-width: 1100px) {
    display: block;
    position: absolute;
    background: url(${world});
    background-size: contain;
    width: ${rem(1004)}rem;
    height: ${rem(493)}rem;
    right: 0;
    top: ${rem(313)}rem;
    right: ${rem(-103)}rem;
    background-repeat: no-repeat;
  }
`
