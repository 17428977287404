import React, { useRef } from "react"
import {
  useDeviceType,
  DEVICE_TYPE_DESKTOP,
  DEVICE_TYPE_MOBILE,
} from "../utils/deviceType"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "./Carousel.scss"

export function MobileOnlyCarousel({ children, ...props }) {
  const deviceType = useDeviceType()
  return (
    <>
      {deviceType !== DEVICE_TYPE_MOBILE && <>{children}</>}
      {deviceType === DEVICE_TYPE_MOBILE && (
        <>
          <Carousel {...props}>{children}</Carousel>
        </>
      )}
    </>
  )
}
export function Carousel(props) {
  const slider = useRef()
  const next = () => {
    if (slider.current) {
      slider.current.slickNext()
    }
  }
  const prev = () => {
    if (slider.current) {
      slider.current.slickPrev()
    }
  }
  if (props.goTo !== undefined && slider.current) {
    slider.current.slickGoTo(props.goTo)
  }
  const settings = {
    dots: true,
    arrows: false,
    appendDots: dots => (
      <div>
        <ul className={props.light ? "light" : "dark"}>
          <li className="slick-prev-custom">
            <button onClick={prev}></button>
          </li>
          {dots}
          <li className="slick-next-custom">
            <button onClick={next}></button>
          </li>
        </ul>
      </div>
    ),
  }
  return (
    <div style={{ paddingBottom: props.methodology ? 0 : "70px" }}>
      <Slider className={props.className} ref={slider} {...settings}>
        {props.children}
      </Slider>
    </div>
  )
}
