import React, { useEffect, useRef, useState } from "react"

import styled, { css, ThemeProvider } from "styled-components"
import { darkTheme } from "../utils/themes"
import { LandingPageHeader } from "src/components/Header"
import { rem } from "../utils/styling"
import { LandingBannerContent, NewLandingBanner } from "src/components/Banner"
import { PageSection } from "src/components/PageSection"
import Button from "../components/Button"
import { HiChevronRight } from "react-icons/hi"
import Container from "../components/Container"
import { LightFooter } from "../components/Footer"
import {
  DEVICE_TYPE_DESKTOP,
  DEVICE_TYPE_MOBILE,
  useDeviceType,
} from "../utils/deviceType"
import Placholdertimeline1 from "../images/placeholder-timeline1.png"
import Placholdertimeline2 from "../images/placeholder-timeline2.svg"
import Placholdertimeline3 from "../images/placeholder-timeline3.svg"

import { ReactTypeformEmbed } from "react-typeform-embed"
import SEO from "src/components/seo"
import { Helmet } from "react-helmet"
import CaseStudies from "src/components/CaseStudy/CaseStudies"
import Benefits from "src/components/Benefits"
import { enhancementsCardItemsData } from "src/data/cardItems"
import { MobileOnlyCarousel } from "src/components/Carousel"
import { EnhancementsMobileCardItemsData } from "src/data/MobileCardItems"
import clientIcons, { clientIconsDesktop } from "src/data/clients"

import caseStudyData from "src/data/caseStudyData"

const Content = styled.div`
  width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media screen and (min-width: 1100px) {
    width: 100%;
  }
  @media screen and (max-width: 500px) {
    width: 100%;
    padding: 0;
  }
`

const ContainerForFirstFold = styled(Content)`
  justify-content: space-between;
`

const HWContainer = styled.div`
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media screen and (min-width: 1100px) {
    flex-direction: row;
    justify-content: space-between;
    margin-top: 50px;
    margin-bottom: 5rem;
  }

  &:last-child {
    margin-right: 0;
  }
`

const HWSection = styled.div`
  width: 100%;
`
const HWRectangle = styled.div`
  background: linear-gradient(180deg, #03162c 0%, #0b3c76 100%);
  backdrop-filter: blur(20px);
  border-radius: 12px;
  /* max-width: 400px; */
  max-height: 218px;
  position: relative;

  margin-top: 50px;

  @media screen and (min-width: 1100px) and (max-width: 1279px) {
    width: 350px;
    margin-top: 0;
  }
  @media screen and (min-width: 1280px) {
    width: 400px;
    height: 218px;
    margin-top: 0;
  }

  margin-left: auto;
  margin-right: auto;
  width: 356px;
  height: 198px;
  padding: 0;

  @media screen and (max-width: 1099px) {
    margin-left: auto;
    margin-right: auto;
    width: 360px !important;
    height: 198px;
    padding: 0;
    margin-top: 70px;
  }
`
const TEMP = styled.div`
  @media screen and (min-width: 1100px) {
    margin-right: 20px;
  }
`
const HWRectangleText = styled.span`
  color: white;
  position: absolute;
  top: 22%;
  right: 5%;
  text-align: left;
  font-weight: 700;
  font-size: 16px;
`

const HWRectangleText1 = styled(HWRectangleText)`
  text-align: center;
  top: 55%;
  right: 39%;
`
const HWRectangleText3 = styled(HWRectangleText)`
  text-align: center;
  top: 50%;
  right: 35%;
`
const HWRectangleTitle = styled.h2`
  font-family: Inter;
  font-style: normal;
  font-weight: 900;
  font-size: 22px;
  line-height: 27px;
  text-align: center;
  color: #000000;
`

const HWRectangleSubText = styled.p`
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #000000;
  opacity: 0.7;
  margin-top: 7px;
  padding-left: 10px;
  padding-right: 10px;
  width: 105%;
`

const HWStep = styled.div`
  margin-top: 40px;
  margin-bottom: 8px;
  font-family: Inter;
  font-weight: bold;
  font-size: 12px;
  letter-spacing: 10px;
  color: #00b8c5;

  @media screen and (max-width: 1100px) {
    margin-top: 20px;
  }
`
const HWImage1 = styled.img`
  position: absolute;
  top: -25%;
  left: 0%;
`
const HWImage2 = styled.img`
  position: absolute;
  top: -20%;
  height: 115%;
  left: 0.5%;
  @media screen and (min-width: 1101px) and (max-width: 1279px) {
    height: 112%;
    top: -18%;
    left: -0.2%;
  }
`
const HWImage3 = styled.img`
  height: 115px;
  position: absolute;
  top: -15%;
  left: 0;
`
const ProcessContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: ${rem(80)}rem 0 ${rem(40)}rem;
  @media screen and (max-width: 550px) {
    margin: ${rem(40)}rem 0 ${rem(10)}rem;
  }
`

const ServiceSection = styled.div`
  background-color: #fff;
  position: relative;
  width: 100vw;
  @media screen and (min-width: 1100px) {
    margin-top: 6rem;
  }
`

const CSContainer = styled.div`
  margin: ${rem(20)}rem 0;
  padding: ${rem(0)}rem 0;
  border-radius: ${rem(20)}rem;
  @media screen and (min-width: 1100px) {
    margin: ${rem(80)}rem 52px;
    padding: 0;
  }
  @media screen and (max-width: 500px) {
    padding: ${rem(10)}rem 28px;
  }
`
const ProcessContainer = styled(Container)`
  width: auto;
  background-color: #f5feff;
  border-radius: 20px;
  padding-bottom: 70px;
  border: 1px solid #eeeeee;
  @media screen and (max-width: 1100px) {
    padding-bottom: 0;
  }
`

const CardRow = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: ${rem(50)}rem 0 20px;

  &:last-child {
    margin: ${rem(20)}rem 0;
  }

  @media screen and (min-width: 1100px) {
    flex-direction: row;
    justify-content: center;
  }
  @media screen and (max-width: 550px) {
    margin: 0;
  }
`

const CardTitle = styled.h2`
  color: #212121;
  font-weight: 900;
  font-size: ${rem(18)}rem;
  margin-top: ${rem(20)}rem;
  padding: 0 ${rem(15)}rem;
  text-align: left;
  @media screen and (min-width: 1100px) {
    font-size: ${rem(19)}rem;
    margin-top: ${rem(30)}rem;

    margin-bottom: 5px;
    padding: 0;
  }
`

const Card = styled.div`
  box-shadow: ${props => (props.shadow ? props.shadow : "")};
  background-color: ${props => (props.color ? props.color : "#fff")};
  border-radius: 8px;
  padding: ${rem(30)}rem ${rem(12)}rem;
  margin-bottom: 5px;
  border: 1px solid #eeeeee;
  box-sizing: border-box;
  transition: all 0.3s ease;

  &:last-child {
    margin-right: 0;
  }
  &:hover {
    ${props =>
      props &&
      props.allowHover &&
      css`
        cursor: pointer;
      `}
  }

  width: 229px !important;
  min-height: 198px !important;
  height: auto;
  @media screen and (min-width: 1100px) {
    width: 196px !important;
    min-height: 210px;
    height: auto;
    margin: 0 35px 0 0;
    padding: 30px 11px 30px 15px;

    &:last-child {
      margin: 0;
    }
  }
`

const CardHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: ${rem(15)}rem 0;
  padding: 0 ${rem(15)}rem;
  @media screen and (min-width: 1100px) {
    justify-content: flex-start;
    padding: 0;
    margin: 0;
  }
  @media screen and (max-width: 550px) {
    margin: 0;
  }
`

const CardImage = styled.img`
  height: ${props =>
    props.height ? `${rem(props.height)}rem` : `${rem(35)}rem`};
  width: ${props => (props.width ? `${rem(props.width)}rem` : `${rem(35)}rem`)};
  margin-right: ${rem(5)}rem;

  &:last-child {
    margin-right: 0;
  }

  @media screen and (min-width: 1100px) {
    height: ${props =>
      props.height ? `${rem(props.height)}rem` : `${rem(40)}rem`};
    width: ${props =>
      props.width ? `${rem(props.width)}rem` : `${rem(40)}rem`};
    margin-right: ${rem(5)}rem;
    &:last-child {
      margin-right: 0;
    }
  }
`

const CardText = styled.p`
  font-size: ${rem(14)}rem;
  font-weight: 300;
  color: ${props => (props.color ? props.color : "#000")};
  line-height: ${props =>
    props.lineHeight ? `${rem(props.lineHeight)}rem` : `${rem(26)}rem`};
  text-align: left;
  opacity: 0.7;
  padding: 0 ${rem(15)}rem;
  @media screen and (min-width: 1100px) {
    // width: 20rem;
    text-align: left;
    font-size: ${rem(16)}rem;
    font-weight: 500;
    padding: 0;
  }
  @media screen and (max-width: 500px) {
    line-height: 1.6rem;
    text-align: left;
    width: 100%;
  }
`

const HWTag = styled.span`
  color: #fff;
  text-transform: uppercase;
  background: linear-gradient(260.25deg, #ff6a00 -10.66%, #ee0979 113%);
  border-radius: 80px;
  padding: ${rem(10)}rem ${rem(40)}rem;
  font-weight: 900;
  font-size: ${rem(12)}rem;

  @media screen and (min-width: 1100px) {
    font-size: ${rem(13)}rem;
  }
`

const HWTagTwo = styled(HWTag)`
  background: linear-gradient(260.25deg, #667eea -10.66%, #764ba2 113%);
`

const HWTagThree = styled(HWTag)`
  background: linear-gradient(262.65deg, #005bea -27.25%, #00c6fb 124.05%);
`

const HWTitle = styled.h3`
  background: linear-gradient(260.25deg, #f093fb -10.66%, #f5576c 113%);
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 800;
  font-size: ${rem(24)}rem;
  padding: ${rem(20)}rem 0;
  text-align: center;
  @media screen and (min-width: 1100px) {
    font-size: ${rem(28)}rem;
  }
`

const HWTitleOne = styled(HWTitle)`
  background: linear-gradient(260.25deg, #ff6a00 -10.66%, #ee0979 113%);
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

const HWTitleTwo = styled(HWTitle)`
  background: linear-gradient(260.25deg, #667eea -10.66%, #764ba2 113%);
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

const HWTitleThree = styled(HWTitle)`
  background: linear-gradient(262.65deg, #005bea -27.25%, #00c6fb 124.05%);
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

const HWText = styled.p`
  color: #000;
  font-weight: 300;
  text-align: center;
  font-size: 20px;
  line-height: 37px;
  opacity: 0.6;
  margin: 0 auto;
  @media screen and (min-width: 1100px) {
    font-size: ${rem(20)}rem;
    width: 50%;
  }
`

const HWTimeline = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: ${rem(40)}rem 0;
  @media screen and (min-width: 1100px) {
    flex-direction: row;
  }
`

const HWTimelineTwo = styled(HWTimeline)`
  margin: ${rem(40)}rem 0 0;

  &:last-child {
    margin: 0;
  }

  @media screen and (min-width: 1100px) {
    &:last-child {
      margin: 0 0 ${rem(40)}rem;
    }
  }
`

const HWTimelineMiddle = styled(HWTimelineTwo)`
  margin: 0;

  &:last-child {
    margin: 0;
  }

  @media screen and (min-width: 1100px) {
    &:last-child {
      margin: 0 0 ${rem(40)}rem;
    }
  }
`

const HWTimelineTextContainer = styled.div`
  flex: 1;
  padding: 0;
  @media screen and (min-width: 1100px) {
    padding: ${rem(70)}rem 0 0;
  }
`
const HWTimelineTitle = styled.h3`
  color: #212121;
  font-size: ${rem(18)}rem;
  font-weight: 800;
  text-align: center;

  @media screen and (min-width: 1100px) {
    font-size: ${rem(20)}rem;
    text-align: right;
  }
`

const HWTimelineText = styled.p`
  font-size: ${rem(13)}rem;
  text-align: center;
  line-height: 27px;
  padding-top: ${rem(10)}rem;
  @media screen and (min-width: 1100px) {
    float: right;
    width: ${rem(350)}rem;
    padding-top: 0;
    font-size: 14px;
    line-height: 27px;
    text-align: right;
    color: #000000;
    opacity: 0.5;
  }
`

const HorizontalNavListSeparator = styled.li`
  padding: 0;
  margin: 0 ${rem(40)}rem;
  width: ${rem(3)}rem;
  height: ${rem(80)}rem;
  background: #fcb13f;
  display: block;
`

const SeparatorContainer = styled.div`
  position: relative;
`

const MobileSeparatorContainer = styled(SeparatorContainer)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: ${rem(10)}rem;
`

const MobileTimelineVerticalSeparator = styled.div`
  width: ${rem(2)}rem;
  background: linear-gradient(#fff, #aaa);
  height: ${rem(40)}rem;
`

const MobileSeparator = styled(MobileTimelineVerticalSeparator)`
  background: linear-gradient(#aaa, #fff);
  margin: ${rem(30)}rem 0;
  height: ${rem(40)}rem;
`

const TimelineBullet = styled.div`
  border: 2px solid #f5576c;
  background-color: #fff;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  position: absolute;
  top: ${rem(76)}rem;
  left: 39%;
`

const TimelineBulletTwo = styled(TimelineBullet)`
  border: 2px solid #764ba2;
`

const TimelineBulletThree = styled(TimelineBullet)`
  border: 2px solid #005bea;
  top: ${rem(60)}rem;
`

const MobileTimelineBullet = styled(TimelineBullet)`
  left: calc(50% - 8px);
  top: ${rem(40)}rem;
`

const MobileTimelineBulletTwo = styled(MobileTimelineBullet)`
  border: 2px solid #764ba2;
`

const TimelineVerticalSeparator = styled(HorizontalNavListSeparator)`
  height: ${rem(550)}rem;
  width: ${rem(2)}rem;
  background: linear-gradient(#fff, #aaa, #fff);
`

const TimelineVerticalSeparatorTwo = styled(TimelineVerticalSeparator)`
  background: linear-gradient(#fff, #aaa, #aaa);
  height: ${rem(480)}rem;
`

const TimelineVerticalSeparatorMiddle = styled(TimelineVerticalSeparator)`
  background: linear-gradient(#aaa, #aaa, #aaa);
  height: ${rem(480)}rem;
`

const TimelineVerticalSeparatorTwoB = styled(TimelineVerticalSeparatorTwo)`
  background: linear-gradient(#aaa, #aaa, #fff);
  height: ${rem(450)}rem;
`

const TimelineVerticalSeparatorThree = styled(TimelineVerticalSeparator)`
  background: linear-gradient(#fff, #aaa);
  height: ${rem(60)}rem;
`

const HWTimelineImageContainer = styled.div`
  flex: 1;
  padding: ${rem(30)}rem 0 0;
  position: relative;
  @media screen and (min-width: 1100px) {
  }
`

const HWSectionOneImageOne = styled.img`
  width: 90%;
  @media screen and (min-width: 1100px) {
    width: unset;
    position: absolute;
    left: -${rem(10)}rem;
    top: ${rem(30)}rem;
  }
`

const HWSectionOneImageSlack = styled.img`
  width: 80%;
  @media screen and (min-width: 1100px) {
    width: 90%;
    position: absolute;
    left: -${rem(10)}rem;
    top: ${rem(30)}rem;
  }
`

const HWSectionThreeImage = styled.img``

const LandingPageSection = styled(PageSection)`
  padding: ${rem(50)}rem 0;
  position: relative;
  background-color: ${props => (props.color ? props.color : "transparent")};
  @media screen and (min-width: 1100px) {
    padding: ${rem(10)}rem 0;
  }
  @media screen and (max-width: 1100px) {
    padding: ${rem(5)}rem 0;
  }
  @media screen and (max-width: 550px) {
    padding: ${rem(20)}rem 0;
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 550px) {
    margin: 10px 0;
    padding-top: 0;
  }
  @media screen and (min-width: 1100px) {
    padding-top: 40px;
  }
`

const SectionTitle = styled.h2`
  font-weight: 800;
  font-family: Inter;
  font-style: normal;

  /* line-height: 49px; */
  font-size: 24px;
  color: ${props => (props.color ? props.color : "#000000")};
  text-align: center;
  align-self: center;

  > em {
    font-style: normal;
    color: #00b8c5;
  }

  @media screen and (min-width: 1100px) {
    font-size: 38px;
  }
`
const ServicesTitle = styled(SectionTitle)`
  font-size: 26px;
  margin-bottom: 10px;
  @media screen and (min-width: 1100px) {
    font-weight: 900;
    font-size: 38px;
    line-height: 49px;
    margin-bottom: 5px;
  }
`
const SectionSubtitle = styled.p`
  text-align: center;
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;

  font-size: 14px;
  line-height: 29px;
  margin-bottom: 0;
  max-width: 550px;
  @media screen and (min-width: 1100px) {
    font-size: 20px;
    line-height: 34px;
    margin-bottom: 28px;
    max-width: 1300px;
  }
`
export const PageButton = styled(Button)`
  font-size: ${rem(18)}rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  @media screen and (max-width: 550px) {
    font-size: ${rem(15)}rem;
    padding: 0.4rem 1rem;
  }
  @media screen and (min-width: 1100px) {
    margin: 0;
  }
`

const CTAContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${rem(100)}rem ${rem(140)}rem;
  background: linear-gradient(283.7deg, #03162c 28.07%, #4d5357 185.43%);
  border-radius: 20px;
  width: 90%;
  margin: 0 auto -${rem(180)}rem;
  @media screen and (max-width: 500px) {
    padding: 60px;
  }
  @media screen and (min-width: 1100px) {
    width: 894px;
    position: relative;
    top: 50px;
  }
`

const CTAContainerInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const BannerRow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  @media screen and (min-width: 1100px) {
    flex-direction: row;
  }
`

const BannerColumn = styled.div`
  flex: 1;
`
const BannerColumnVideo = styled.div`
  flex: 1;
`

const PageTitle = styled.h1`
  padding: ${rem(10)}rem 0 0.7rem;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 2rem;
  line-height: 1.3;
  max-width: 100%;
  margin: 0 auto;
  text-align: center;
  letter-spacing: -0.04em;
  color: #000;

  > em {
    font-style: normal;
    color: #00b8c5;
  }

  @media screen and (min-width: 1100px) {
    font-size: 3.7rem;
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
    line-height: 1.2;
    text-align: left;
  }
  @media screen and (max-width: 500px) {
    width: 100%;
    font-size: 1.8rem;
    padding: 0;
    margin-bottom: 0.7rem;
  }
`

const PageText = styled.p`
  text-align: center;
  color: #000;
  font-size: ${rem(16)}rem;
  font-weight: normal;
  width: 100%;
  opacity: 0.6;
  letter-spacing: -0.02em;
  line-height: 36px;

  margin-right: auto;
  margin-left: auto;

  em {
    font-weight: bold;
    font-style: normal;
  }

  @media screen and (min-width: 1100px) {
    font-size: ${rem(22)}rem;
    text-align: left;
    margin-bottom: ${rem(40)}rem;

    margin-right: auto;
    margin-left: 25px;
    width: 80%;
  }
  @media screen and (min-width: 550px) and (max-width: 1099px) {
    line-height: ${rem(32)}rem;
    width: 100%;
  }
`

const SectionText = styled.h2`
  font-size: ${rem(24)}rem;
  font-weight: 800;
  color: ${props => (props.color ? props.color : "#000")};
  text-align: ${props => (props.align ? props.align : "center")};
  margin: 0 0 ${rem(20)}rem;
  line-height: ${rem(34)}rem;

  > em {
    font-style: normal;
    color: #fcb13f;
  }

  @media screen and (min-width: 1100px) {
    font-size: ${rem(30)}rem;
    font-weight: 900;
  }
  @media screen and (max-width: 550px) {
    margin: 0;
    text-align: center;
    width: 100%;
  }
`

const SectionTextHero = styled(SectionText)`
  color: black;
  text-align: center;
  margin-top: ${rem(30)}rem;
  @media screen and (min-width: 1100px) {
    font-size: ${rem(20)}rem;
    text-align: left;
  }
  @media screen and (max-width: 550px) {
    font-size: ${rem(16)}rem;
    margin-bottom: 0.3rem;
  }
`

const VideoContainer = styled.div`
  margin-top: 1.5rem;
  display: flex;
  justify-content: flex-end;

  iframe {
    border-radius: 8px;
    height: 360px;
    width: 640px;
    @media screen and (max-width: 1500px) {
      width: 580px;
      height: 326.76px;
    }
    @media screen and (max-width: 1300px) {
      width: 530px;
      height: 298.59px;
    }
    @media screen and (max-width: 1200px) {
      width: 460px;
      height: 259.15px;
    }
    @media screen and (max-width: 1150px) {
      width: 410px;
      height: 230.99px;
    }
    @media screen and (max-width: 550px) {
      width: 270px;
      height: 152.11px;
    }
  }
`

const BulletRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: flex-wrap;
  margin: 0;
  /* padding-right: 20px;
  padding-left: 20px; */
  padding-bottom: ${rem(10)}rem;
  @media screen and (min-width: 1100px) {
    flex-direction: row;
    margin: 0 3rem 3rem;
  }
  @media screen and (max-width: 550px) {
    padding: 0 1rem;
  }

  /* @media screen and (min-width: 367px) and (max-width: 424px) {
    flex-direction: row;
  } */
`
const BulletContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0;
  padding-bottom: ${rem(10)}rem;
  @media screen and (min-width: 1100px) {
    margin: 0 3rem 3rem;
  }
  @media screen and (min-width: 551px) and (max-width: 1099px) {
    padding: 1rem 1rem;
    margin: 0 1rem 2rem;
  }
  @media screen and (max-width: 550px) {
    padding: 1rem 1rem;
  }
`
const BulletTitle = styled.h1`
  font-family: Raleway;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #000000;

  margin-bottom: 10px;
`
const ClientImage = styled.img`
  display: inline-block;
  filter: grayscale(100%) opacity(40%);
  margin-bottom: 10px;
  @media screen and (min-width: 1100px) {
    display: none;
  }
`
const ClientImageDesktop = styled.img`
  display: inline-block;
  filter: grayscale(100%) opacity(40%);
  margin-bottom: 10px;
  @media screen and (max-width: 1099px) {
    display: none;
  }
`
const ClientImg1 = styled.img`
  display: none;
  @media screen and (min-width: 1100px) {
    display: block;
    width: 92%;
    position: relative;
    left: -33px;
  }
`
const ClientImg2 = styled.img`
  max-width: 2rem;
  width: 7%;
  display: none;
  @media screen and (min-width: 1100px) {
    display: block;
    width: 7%;
    max-width: 3rem;
    position: relative;
    top: -5px;
    left: 3px;
  }
`
const BulletPoint = styled.div`
  margin: ${rem(10)}rem ${rem(25)}rem;
  padding: ${rem(30)}rem ${rem(20)}rem;
  background: linear-gradient(297.28deg, #09203f 19.47%, #537895 197.88%);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
  border-radius: ${rem(8)}rem;
  min-width: ${rem(250)}rem;
  @media screen and (min-width: 1100px) {
    margin: 0 ${rem(25)}rem;
  }
  @media screen and (max-width: 550px) {
    width: 100%;
  }
`

const PointTitle = styled.h2`
  color: #fff;
  text-align: center;
  font-size: ${rem(32)}rem;
  font-weight: 900;
`

const PointText = styled.p`
  color: #ffffff;
  text-align: center;
  font-weight: 500;
  font-size: ${rem(12)}rem;
  text-transform: uppercase;
`

const Code = ({ location }) => {
  const typeformRef = useRef()
  const onClickTypeform = () => {
    typeformRef.current && typeformRef.current.typeform.open()
  }
  const deviceType = useDeviceType()

  const [caseStudyDataMapping, setCaseStudyDataMapping] = useState()
  const [goToIndex, setGoToIndex] = useState(0)

  useEffect(() => {
    const mapping = caseStudyData.map((el, idx) => {
      return { id: idx, typeText: el.typeText }
    })
    setCaseStudyDataMapping(mapping)
  }, [])

  const findGotoIndex = (typeText = "#") => {
    const id = caseStudyDataMapping.filter(
      el => el.typeText === typeText.slice(1)
    )[0]

    setGoToIndex(id && id.id)
    const ele = document.getElementById("case-study")
    if (ele && id && id.id !== undefined) {
      //body top
      const bodyReact = document.body.getBoundingClientRect().top
      //element pos
      const pos = ele.getBoundingClientRect().top - bodyReact
      //offset for sticky header
      const headerOffset = 70
      //offset position from the top
      const offsetPosition = pos - headerOffset

      window.scrollTo({ left: 0, top: offsetPosition, behavior: "smooth" })
    }
  }

  return (
    <ThemeProvider theme={darkTheme}>
      <Container />
      <LandingPageHeader />
      <ReactTypeformEmbed
        popup
        autoOpen={false}
        hideHeaders
        hideFooter
        url="https://jfox813621.typeform.com/to/ygspaPkA"
        ref={typeformRef}
      />
      <NewLandingBanner>
        <LandingBannerContent style={{ marginBottom: 30 }}>
          <ContainerForFirstFold>
            <SEO
              title="Code as a Service"
              description="Upfront fixed price by task (think Jira card), Execute 1 task or multiple concurrent tasks, Pay for completed tasks (not hours worked)"
              location={location}
            />
            <BannerRow>
              <BannerColumn>
                <PageTitle>
                  <em>Code as a Service</em>
                </PageTitle>
                <PageText>
                  <ul style={{ textAlign: "left", listStyle: "disc" }}>
                    <li>Upfront fixed price by task (think Jira card)</li>
                    <li>Execute 1 task or multiple concurrent tasks</li>
                    <li>Pay for completed tasks (not hours worked)</li>
                  </ul>
                </PageText>
                {deviceType === DEVICE_TYPE_DESKTOP ? (
                  <>
                    <SectionTextHero>
                      Get started with your first task
                    </SectionTextHero>
                    <div onClick={onClickTypeform}>
                      <PageButton type="solid">
                        Share Task <HiChevronRight size={22} />
                      </PageButton>
                    </div>
                  </>
                ) : (
                  <div />
                )}
              </BannerColumn>
              <BannerColumnVideo>
                <VideoContainer>
                  <iframe
                    src="https://player.vimeo.com/video/486435961?autoplay=0&loop=1"
                    allow="autoplay; fullscreen"
                    allowFullScreen
                  />
                </VideoContainer>
              </BannerColumnVideo>
              {deviceType === DEVICE_TYPE_MOBILE ? (
                <>
                  <SectionTextHero style={{ marginTop: 20 }}>
                    Get started with your first task
                  </SectionTextHero>
                  <div onClick={onClickTypeform}>
                    <PageButton type="solid">
                      Share Task <HiChevronRight size={22} />
                    </PageButton>
                  </div>
                </>
              ) : (
                <div />
              )}
            </BannerRow>
          </ContainerForFirstFold>
        </LandingBannerContent>
      </NewLandingBanner>
      <LandingPageSection color={"#fff"}>
        <BulletContainer>
          <BulletTitle>our clients</BulletTitle>
          <BulletRow>
            <div style={{ marginBottom: 5 }}>
              {clientIcons[0].map(el => (
                <ClientImage src={el.image} alt="" style={el.style} />
              ))}
            </div>
            <div>
              {clientIcons[1].map(el => (
                <ClientImage src={el.image} alt="" style={el.style} />
              ))}
            </div>

            {clientIconsDesktop.map(el => (
              <ClientImageDesktop src={el.image} alt="" style={el.style} />
            ))}
          </BulletRow>
        </BulletContainer>
      </LandingPageSection>

      {deviceType === DEVICE_TYPE_MOBILE ? (
        <>
          <LandingPageSection style={{ paddingBottom: "2rem" }}>
            <ServiceSection>
              <CSContainer>
                <ProcessContainer>
                  <ProcessContent>
                    <ServicesTitle> Services Supported</ServicesTitle>
                    <CardRow className="card-row">
                      <MobileOnlyCarousel>
                        {EnhancementsMobileCardItemsData.map((card, index) => (
                          <Card
                            allowHover={card.to}
                            onClick={() => findGotoIndex(card.to)}
                            key={index}
                            color={card.color}
                            shadow={card.shadow}
                          >
                            <CardHeader>
                              {card.images.map((image, index) => (
                                <CardImage
                                  key={index}
                                  src={image.image}
                                  alt={image.alt}
                                />
                              ))}
                            </CardHeader>
                            <CardTitle>{card.title}</CardTitle>
                            <CardText>{card.descriptions}</CardText>
                          </Card>
                        ))}
                      </MobileOnlyCarousel>
                    </CardRow>
                  </ProcessContent>
                </ProcessContainer>
              </CSContainer>
            </ServiceSection>
          </LandingPageSection>
          <LandingPageSection
            style={{ marginTop: "2rem", marginBottom: "7rem" }}
          >
            <Container style={{ padding: 0 }}>
              <SectionTitle>How It Works</SectionTitle>
              <Wrapper>
                <HWContainer>
                  <>
                    <TEMP
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        marginBottom: 20,
                      }}
                    >
                      <HWRectangle>
                        <HWImage1
                          src={Placholdertimeline1}
                          alt="placeholdler"
                        />
                        <HWRectangleText1>
                          Get started
                          <br />
                          instantly
                        </HWRectangleText1>
                      </HWRectangle>

                      <div
                        style={{
                          width: "85%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          marginRight: "auto",
                          marginLeft: "auto",
                        }}
                      >
                        <HWStep>ONE</HWStep>
                        <HWRectangleTitle>Share a task</HWRectangleTitle>
                        <HWRectangleSubText>
                          Share your first task from your product backlog and
                          let our Customer Success Analysts take over.
                        </HWRectangleSubText>
                      </div>
                    </TEMP>
                    <TEMP
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        // justifyContent: "center",
                        marginBottom: 20,
                      }}
                    >
                      <HWRectangle>
                        <HWImage2
                          src={Placholdertimeline2}
                          alt="placeholdler"
                        />
                        <HWRectangleText>
                          Approve
                          <br />
                          fixed
                          <br />
                          bids
                        </HWRectangleText>
                      </HWRectangle>

                      <div
                        style={{
                          width: "85%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "cenetr",
                          marginRight: "auto",
                          marginLeft: "auto",
                        }}
                      >
                        <HWStep>TWO</HWStep>
                        <HWRectangleTitle>We go to work</HWRectangleTitle>
                        <HWRectangleSubText>
                          We import your tasks into our platform and work with
                          curated engineers to obtain fixed price bids.
                        </HWRectangleSubText>
                      </div>
                    </TEMP>
                    <TEMP
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        // justifyContent: "center",
                      }}
                    >
                      <HWRectangle>
                        <HWImage3
                          src={Placholdertimeline3}
                          alt="placeholdler"
                        />
                        <HWRectangleText3>
                          One Platform <br /> to manage it all
                        </HWRectangleText3>
                      </HWRectangle>
                      <div
                        style={{
                          width: "85%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          marginRight: "auto",
                          marginLeft: "auto",
                        }}
                      >
                        <HWStep>THREE</HWStep>
                        <HWRectangleTitle>
                          Watch it come together
                        </HWRectangleTitle>
                        <HWRectangleSubText>
                          Track progress as our Delivery Leads quarterback your
                          request to completion.
                        </HWRectangleSubText>
                      </div>
                    </TEMP>
                  </>
                </HWContainer>
              </Wrapper>
            </Container>
          </LandingPageSection>
        </>
      ) : (
        <>
          <LandingPageSection style={{ marginTop: "2rem" }}>
            <Container style={{ padding: 0 }}>
              <SectionTitle>How It Works</SectionTitle>
              <Wrapper>
                <HWContainer>
                  <>
                    <TEMP
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        // justifyContent: "center",
                      }}
                    >
                      <HWRectangle>
                        <HWImage1
                          src={Placholdertimeline1}
                          alt="placeholdler"
                        />
                        <HWRectangleText1>
                          Get started
                          <br />
                          instantly
                        </HWRectangleText1>
                      </HWRectangle>
                      <div
                        style={{
                          width: "80%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          // marginRight: "auto",
                          // marginLeft: "auto",
                        }}
                      >
                        <HWStep>ONE</HWStep>
                        <HWRectangleTitle>Share a task</HWRectangleTitle>
                        <HWRectangleSubText>
                          Share your first task from your product backlog and
                          let our Customer Success Analysts take over.
                        </HWRectangleSubText>
                      </div>
                    </TEMP>
                    <TEMP
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <HWRectangle>
                        <HWImage2
                          src={Placholdertimeline2}
                          alt="placeholdler"
                        />
                        <HWRectangleText>
                          Approve
                          <br />
                          fixed
                          <br />
                          bids
                        </HWRectangleText>
                      </HWRectangle>

                      <div
                        style={{
                          width: "80%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <HWStep>TWO</HWStep>
                        <HWRectangleTitle>We go to work</HWRectangleTitle>
                        <HWRectangleSubText>
                          We import your tasks into our platform and work with
                          curated engineers to obtain fixed price bids.
                        </HWRectangleSubText>
                      </div>
                    </TEMP>
                    <TEMP
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <HWRectangle>
                        <HWImage3
                          src={Placholdertimeline3}
                          alt="placeholdler"
                        />

                        <HWRectangleText3>
                          One Platform <br /> to manage it all
                        </HWRectangleText3>
                      </HWRectangle>
                      <div
                        style={{
                          width: "80%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <HWStep>THREE</HWStep>
                        <HWRectangleTitle>
                          Watch it come together
                        </HWRectangleTitle>
                        <HWRectangleSubText>
                          Track progress on platform as our Delivery Leads
                          quarterback your request to completion.
                        </HWRectangleSubText>
                      </div>
                    </TEMP>
                  </>
                </HWContainer>
              </Wrapper>
            </Container>
          </LandingPageSection>
          <LandingPageSection style={{ paddingBottom: "2rem" }}>
            <ServiceSection>
              <CSContainer>
                <ProcessContainer>
                  <ProcessContent>
                    <ServicesTitle> Services Supported</ServicesTitle>
                    {deviceType === DEVICE_TYPE_DESKTOP
                      ? enhancementsCardItemsData.map((cardItem, index) => (
                          <CardRow className="card-row" key={index}>
                            <MobileOnlyCarousel>
                              {cardItem.map((card, index) => (
                                <Card
                                  allowHover={card.to}
                                  onClick={() => findGotoIndex(card.to)}
                                  key={index}
                                  color={card.color}
                                  shadow={card.shadow}
                                >
                                  <CardHeader>
                                    {card.images.map((image, index) => (
                                      <CardImage
                                        key={index}
                                        src={image.image}
                                        alt={image.alt}
                                      />
                                    ))}
                                  </CardHeader>
                                  <CardTitle>{card.title}</CardTitle>
                                  <CardText>{card.descriptions}</CardText>
                                </Card>
                              ))}
                            </MobileOnlyCarousel>
                          </CardRow>
                        ))
                      : null}
                  </ProcessContent>
                </ProcessContainer>
              </CSContainer>
            </ServiceSection>
          </LandingPageSection>
        </>
      )}

      <LandingPageSection color="#fff">
        <Benefits />
      </LandingPageSection>
      <LandingPageSection id="case-study" color={"#fff"}>
        <CaseStudies page="homepage" index={goToIndex} />
        <div id="how-it-works" />
      </LandingPageSection>

      <LandingPageSection>
        <CTAContainer>
          <CTAContainerInner>
            {deviceType === DEVICE_TYPE_DESKTOP ? (
              <SectionTitle color={"#fff"} style={{ paddingBottom: 45 }}>
                Get Started with Your First Task
              </SectionTitle>
            ) : (
              <SectionTitle color={"#fff"} style={{ paddingBottom: 45 }}>
                Get Started with Your First Task
              </SectionTitle>
            )}
            <div onClick={onClickTypeform}>
              <PageButton type="solid">
                Submit Task
                <HiChevronRight size={22} />
              </PageButton>
            </div>
          </CTAContainerInner>
        </CTAContainer>
      </LandingPageSection>
      <LightFooter paddingTop={280} />
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            name: "Archimydes",
            url: "https://archimydes.dev/",
            logo:
              "https://archimydes.dev/static/archimydes-icon-logo-77bff49f7d51329123abcd81239b16a6.png",
            sameAs: [
              "https://www.facebook.com/archimydes",
              "https://twitter.com/archimydes",
              "https://www.linkedin.com/company/archimydes/",
            ],
            contactPoint: [
              {
                "@type": "ContactPoint",
                telephone: "1-571-237-5597",
                contactType: "customer service",
                email: "info@archimydes.com",
                areaServed: "US",
                availableLanguage: "en",
              },
            ],
          })}
        </script>
      </Helmet>
    </ThemeProvider>
  )
}

export default Code
