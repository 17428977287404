import React from "react"
import styled from "styled-components"
import CaseStudyLeft from "src/components/CaseStudy/CaseStudyLeft"
import CaseStudyRight from "src/components/CaseStudy/CaseStudyRight"
import CaseStudyStatCard from "src/components/CaseStudy/CaseStudyStatCard"

const Container = styled.div`
  background: #fafafa;
  border: 1px solid #eeeeee;
  border-radius: 20px;
  padding: 20px;
  margin: 0 50px;
  @media screen and (min-width: 1100px) {
    padding: 40px;
  }
`

const ContainerInner = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 1100px) {
    flex-direction: row;
  }
`

const ContainerLeft = styled.div`
  flex: 1;
`

const ContainerRight = styled.div`
  flex: 1;
`

const StatCardContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
  @media screen and (min-width: 1100px) {
    margin: 20px 0 0;
  }
  @media screen and (max-width: 500px) {
    flex-direction: column;
  }
`

const CaseStudyCard = ({
  left: {
    type,
    typeText,
    companyLogo,
    title,
    description,
    toolsUsed,
    readMoreLink,
  },
  right: { testimonial, personImage, personName, personPosition },
  statCards,
}) => {
  return (
    <Container>
      <ContainerInner>
        <ContainerLeft>
          <CaseStudyLeft
            type={type}
            description={description}
            companyLogo={companyLogo}
            readMoreLink={readMoreLink}
            title={title}
            toolsUsed={toolsUsed}
            typeText={typeText}
          />
        </ContainerLeft>
        <ContainerRight>
          <CaseStudyRight
            personPosition={personPosition}
            testimonial={testimonial}
            personImage={personImage}
            personName={personName}
          />
        </ContainerRight>
      </ContainerInner>
      <StatCardContainer>
        {statCards.map(statCard => (
          <CaseStudyStatCard
            key={statCard.title}
            type={statCard.type}
            title={statCard.title}
          />
        ))}
      </StatCardContainer>
    </Container>
  )
}

export default CaseStudyCard
