import canid from "../images/canid_logo.svg"
import figma from "../images/icons/figma.svg"
import balsamiq from "../images/icons/balsamiq.png"
import pedro from "../images/pedro.png"
import AccurbeatLogo from "src/images/logos/accur.svg"
import java from "src/images/icons/java.svg"
import lambda from "src/images/icons/image2.svg"
import s3 from "src/images/icons/image5.svg"
import langImage6 from "src/images/icons/image6.svg"
import langImage7 from "src/images/icons/image7.svg"
import juan from "src/images/task_page/testimonial2.svg"
import react from "src/images/icons/react.svg"
import ClarusLogo from "src/images/logos/clarus.svg"
import laravel from "src/images/icons/laravel.svg"
import Testimonial1 from "src/images/task_page/testimonial1.svg"

const caseStudyData = [
  {
    for: "homepage",
    type: "enhancements",
    typeText: "plugin",
    companyLogo: ClarusLogo,
    title: "How Clarus R+D built a production plugin for $2,100",
    description:
      "Created a plugin to ingest payroll data from Rippling using Finch’s API for Payroll and HR.",
    toolsUsed: [react, laravel],
    readMoreLink: "/case-study/clarus",
    statCards: [
      {
        title: "5 Days",
        type: "velocity",
      },
      {
        title: "$2,100",
        type: "price",
      },
      {
        title: "3 Tasks",
        type: "scale",
      },
    ],
    testimonialSection: {
      testimonial:
        "It is enormously freeing to be able to put an upfront cost with a feature and have it done quickly. With Archimydes we were able to complete a production deployment of a key integration in 5 days for about $2000.",
      personImage: Testimonial1,
      personName: "Adam Winter",
      personPosition: "CTO, Clarus R+D",
    },
  },
  {
    for: "homepage",
    type: "betaProduct",
    typeText: "microservice",
    companyLogo: AccurbeatLogo,
    title: "How Accurkardia improved its security posture",
    description:
      "Implement a malware / anti-virus scanning service for ECG data files uploaded for analysis by the AI and Analytics engine.",
    toolsUsed: [java, lambda, s3, langImage6, langImage7],
    readMoreLink: "/case-study/accurkardia",
    statCards: [
      {
        title: "9 Days",
        type: "velocity",
      },
      {
        title: "$5,400",
        type: "price",
      },
      {
        title: "15 Tasks",
        type: "scale",
      },
    ],
    testimonialSection: {
      testimonial:
        "Archimydes very quickly has become an integral part of our toolkit for success. Working within the constraints of an FDA-regulated product they were able to build an entirely custom anti-malware/anti-virus solution in less than two weeks!",
      personImage: juan,
      personName: "Juan Jiminez",
      personPosition: "CEO, Accurkardia",
    },
  },
  {
    for: "homepage",
    type: "prototyping",
    typeText: "design",
    companyLogo: canid,
    title: "Canid went from wireframes to high-fidelity mocks in 72 hours.",
    description:
      "Pedro required a very fast turn around time for high fidelity mocks of 10 screens, to get in front of prospective customers.",
    toolsUsed: [balsamiq, figma],
    readMoreLink: null,
    statCards: [
      {
        title: "3 days",
        type: "velocity",
      },
      {
        title: "$1,450",
        type: "price",
      },
      {
        title: "10 Pages",
        type: "scale",
      },
    ],
    testimonialSection: {
      testimonial:
        "I was delighted with the speed, transparency, and quality at which Archimydes was able to deliver on my requirements. I provided them my requirements during Thanksgiving week and they were able to turn it around within a few days at a very reasonable agreed upon price. This included 2 rounds of iterations that I went through with their designer.",
      personImage: pedro,
      personName: "Pedro Sanchez de Lozada",
      personPosition: "Founder, Canid",
    },
  },
]

export default caseStudyData
