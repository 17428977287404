import React from "react"
import styled from "styled-components"
import { rem } from "../utils/styling"

export const PageSection = styled.div`
  padding: ${rem(56)}rem 0;
  position: relative;
  text-align: center;
  @media screen and (min-width: 1100px) {
    padding: ${rem(120)}rem 0;
    text-align: left;
  }
`

export const PageSectionDark = styled(PageSection)`
  background: radial-gradient(
    49.94% 49.94% at 50% 50.06%,
    #162b43 0%,
    #000725 100%
  );
  color: white;
`

export const PageSectionCompact = styled.div`
  position: relative;
  padding: ${rem(72)}rem 0;
`

export const SectionTitle = styled.h2`
  margin: 0;
  padding: 0;
  font-size: ${rem(36)}rem;
  font-weight: bold;
  margin-bottom: ${rem(20)}rem;
  line-height: 1.2;
  @media screen and (min-width: 1100px) {
    font-size: ${rem(60)}rem;
  }
`

export const SectionTitleSmall = styled.h2`
  margin: 0;
  padding: 0;
  font-size: ${rem(24)}rem;
  font-weight: bold;
  margin-bottom: ${rem(20)}rem;
  text-align: center;
  strong {
    color: #00b8c5;
  }
  @media screen and (min-width: 1100px) {
    font-size: ${rem(32)}rem;
  }
`

export const SectionTitleMedium = styled.h2`
  margin: 0;
  padding: 0;
  font-size: ${rem(24)}rem;
  font-weight: bold;
  margin-bottom: ${rem(20)}rem;
  text-align: center;
  strong {
    color: #00b8c5;
  }
  @media screen and (min-width: 1100px) {
    font-size: ${rem(42)}rem;
  }
`

export const SectionContent = styled.div`
  justify-content: space-between;
  font-weight: 500;
  @media screen and (min-width: 1100px) {
    display: flex;
  }
`

export const SectionContentEvenSplit = styled.div`
  font-weight: 500;
  display: block;
  position: relative;
  @media screen and (min-width: 1100px) {
    display: flex;
    justify-content: space-between;
    > * {
      flex: 1 1 0;
      padding: ${rem(22)}rem;
    }
  }
`

export const SectionContentLeft = styled.div`
  @media screen and (min-width: 1100px) {
    width: ${rem(450)}rem;
  }
`

export const SectionContentRight = styled.div`
  @media screen and (min-width: 1100px) {
    width: ${rem(552)}rem;
  }
`
