import styled from "styled-components"
const Container = styled.div`
  width: 100%;
  padding: 0 26px;
  margin: auto;
  position: relative;
  box-sizing: border-box;

  @media screen and (min-width: 1100px) {
    flex-direction: column;
    width: 1100px;
    padding: 0 30px;
  }
  @media screen and (min-width: 1280px) {
    width: 1240px;
  }
  @media screen and (min-width: 1600px) {
    width: 1440px;
  }
`
export default Container
