import React from "react"
import styled from "styled-components"

const StatCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border: 1px solid #eeeeee;
  box-sizing: border-box;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  min-width: 115px;
  min-height: 75px;
  margin: 0 10px;
  padding: 0 30px;
  @media screen and (min-width: 1100px) {
    min-width: 180px;
    min-height: 100px;
  }
  @media screen and (max-width: 500px) {
    margin: 5px 0;
    width: 100%;
  }
  h3 {
    font-weight: 900;
    font-size: 18px;
    line-height: 32px;
    text-align: center;
    color: #212121;
    @media screen and (min-width: 1100px) {
      font-size: 26px;
    }
  }
  p {
    font-weight: 500;
    font-size: 10px;
    line-height: 24px;
    text-align: center;
    text-transform: uppercase;
    color: #212121;
    @media screen and (min-width: 1100px) {
      font-size: 12px;
    }
  }
`

const CaseStudyStatCard = ({ title, type }) => {
  return (
    <StatCard>
      <h3>{title}</h3>
      <p>{type}</p>
    </StatCard>
  )
}

export default CaseStudyStatCard
