import { useEffect, useState } from "react"

export const STAGING = "staging"
export const PRODUCTION = "production"

export function useEnvironment() {
  const getEnvironment = () => {
    const href = window.location.href
    const staging =
      href.includes("s.archimydes.dev") || href.includes("localhost")
    return staging ? STAGING : PRODUCTION
  }

  const [environment, setEnvironment] = useState()
  useEffect(() => {
    const setupEnvironment = () => {
      setEnvironment(getEnvironment())
    }
    setupEnvironment()
    window.addEventListener("load", setupEnvironment)
    return () => {
      window.removeEventListener("load", setupEnvironment)
    }
  }, [])
  return environment
}
