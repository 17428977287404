import React from "react"
import styled from "styled-components"
import { rem } from "../utils/styling"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;
`

const Title = styled.h2`
  font-weight: 900;
  font-size: 20px;
  color: ${props => (props.color ? props.color : "#000000")};
  text-align: center;
  align-self: center;
  > em {
    font-style: normal;
    color: #00b8c5;
  }

  @media screen and (min-width: 1100px) {
    font-size: 38px;
  }
`
const BenefitsTitle = styled(Title)`
  padding-right: 40px;
  padding-left: 40px;
`
const Body = styled.p`
  text-align: center;
  color: #000;
  font-size: ${rem(14)}rem;
  font-weight: normal;
  width: 60%;
  opacity: 0.6;
  letter-spacing: -0.02em;
  line-height: 36px;
  em {
    font-weight: bold;
    font-style: normal;
  }

  @media screen and (min-width: 1100px) {
    font-size: ${rem(18)}rem;
  }
  @media screen and (max-width: 500px) {
    line-height: ${rem(32)}rem;
    width: 80%;
  }
`

const TitleAndBody = ({ type, title, body }) => {
  if (type && type === "benefits")
    return (
      <Container>
        <BenefitsTitle>{title}</BenefitsTitle>
        <Body>{body}</Body>
      </Container>
    )
  else
    return (
      <Container>
        <Title>{title}</Title>
        <Body>{body}</Body>
      </Container>
    )
}

export default TitleAndBody
