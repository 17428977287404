import React from "react"
import styled from "styled-components"
import { rem } from "src/utils/styling"

const Container = styled.div`
  background: #ffffff;
  border: 1px solid #ececec;
  box-sizing: border-box;
  box-shadow: 0 0 30px 10px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: 20px;
  margin: 20px 0 0;
  @media screen and (min-width: 1100px) {
    margin: 0;
    padding: 40px;
  }
`

const Testimonial = styled.p`
  font-weight: normal;
  font-size: ${rem(12)}rem;
  line-height: 25px;
  color: #000000;
  opacity: 0.7;
  @media screen and (min-width: 1100px) {
    font-size: ${rem(20)}rem;
    line-height: 40px;
    em {
      font-size: 58px;
      line-height: 37px;
    }
  }
  &:after {
    position: absolute;
    font-weight: 800;
    font-size: 38px;
    line-height: 37px;
    color: #00b8c5;
    opacity: 1;
    content: " \\201D";
  }
  &:before {
    font-weight: 800;
    font-size: 38px;
    line-height: 37px;
    color: #00b8c5;
    opacity: 1;
    content: " \\201C";
  }
`

const PersonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;
`

const PersonImage = styled.img`
  border-radius: 100px;
  height: 50px;
`

const PersonPosition = styled.div`
  font-size: 9px;
  line-height: 11px;
  color: #000000;
  text-align: left !important;
  @media screen and (min-width: 1100px) {
    line-height: 20px;
    font-size: 17px;
  }
`

const PersonName = styled.div`
  font-weight: 800;
  font-size: 10px;
  line-height: 12px;
  color: #000000;
  text-align: left !important;
  @media screen and (min-width: 1100px) {
    line-height: 25px;
    font-size: 21px;
  }
`

const PersonTextContainer = styled.div`
  margin-left: 20px;
`

const CaseStudyRight = ({
  testimonial,
  personImage,
  personName,
  personPosition,
}) => {
  return (
    <Container>
      <Testimonial>{testimonial}</Testimonial>
      <PersonContainer>
        <PersonImage src={personImage} />
        <PersonTextContainer>
          <PersonPosition>{personPosition}</PersonPosition>
          <PersonName>{personName}</PersonName>
        </PersonTextContainer>
      </PersonContainer>
    </Container>
  )
}

export default CaseStudyRight
