import React, { useState } from "react"
import Modal from "react-modal"
import styled, { css } from "styled-components"
import Logo from "./Logo/Logo"
import closeIcon from "../images/arrow-left.svg"
import closeIconDark from "../images/arrow-left-dark.svg"
import menuIcon from "../images/menu.svg"
import menuIconDark from "../images/menu-dark.svg"
import menuIconLight from "../images/menu-light.svg"

const customStyles = {
  content: {
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",
    marginRight: "0",
    border: 0,
    borderRadius: 0,
    zIndex: 5,
    padding: "1.5rem",
    background: "radial-gradient(50% 50% at 50% 50%, #162B43 0%, #000725 100%)",
  },
}

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 1.5rem;
  background: ${prop => prop.theme.banner.background};
  color: ${prop => prop.theme.banner.color};
`

const NewWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 1.5rem;
  background: #fff;
  color: #fff;
`
const LogoWrapper = styled.div`
  div {
    display: flex;
    justify-content: center;
    margin-top: 3px;
    @media screen and (min-width: 1100px) {
      display: block;
    }
  }
`
const MenuWrapper = styled.div`
  margin-top: 3rem;
  button,
  a {
    display: block;
    padding: 0;
    margin: 0;
    padding-bottom: 1rem;
    padding-top: 1rem;
    outline: 0;
    border: 0;
    border-bottom: 1px solid
      ${prop => (prop.theme.logo == "light" ? "#88909C" : "#6A8897")};
    border-radius: 0;
    width: 100%;
    text-align: left;
    font-size: 1rem;
    position: relative;
    color: inherit;
    background: transparent;
    &::after {
      content: " ";
      display: block;
      height: 16px;
      width: 10px;
      background: url(${prop =>
        prop.theme.logo == "light" ? closeIcon : closeIconDark});
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      right: 0;
      top: 50%;
      margin-top: -8px;
      border: 0;
      outline: 0;
      cursor: pointer;
      transform: rotate(180deg);
    }
  }
`

const DarkMenuWrapper = styled.div`
  margin-top: 3rem;
  button,
  a {
    display: block;
    padding: 0;
    margin: 0;
    padding-bottom: 1rem;
    padding-top: 1rem;
    outline: 0;
    border: 0;
    border-bottom: 1px solid #eee;
    border-radius: 0;
    width: 100%;
    text-align: left;
    font-size: 1rem;
    position: relative;
    color: #000;
    background: transparent;
    &::after {
      content: " ";
      display: block;
      height: 16px;
      width: 10px;
      background: url(${closeIconDark});
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      right: 0;
      top: 50%;
      margin-top: -8px;
      border: 0;
      outline: 0;
      cursor: pointer;
      transform: rotate(180deg);
    }
  }

  a.parent-link {
    &::after {
      transform: ${props => (props.open ? "rotate(270deg)" : "rotate(180deg)")};
    }
  }

  .sub-menu {
    margin: 0 0 0 30px;
  }
`

const CloseButton = styled.button`
  display: block;
  height: 18px;
  width: 18px;
  background: url(${prop =>
    prop.theme.logo == "light" ? closeIcon : closeIconDark});
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  left: 26px;
  top: 26px;
  border: 0;
  outline: 0;
  cursor: pointer;
`

const DarkCloseButton = styled.button`
  display: block;
  height: 18px;
  width: 18px;
  background: url(${closeIconDark});
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  left: 26px;
  top: 26px;
  border: 0;
  outline: 0;
  cursor: pointer;
`

const OpenButton = styled.button`
  z-index: 10;
  display: block;
  height: 20px;
  width: 20px;
  background: url(${prop =>
    prop.theme.logo == "light" ? menuIcon : menuIconDark});
  background-size: contain;
  background-repeat: no-repeat;
  border: 0;
  outline: 0;
  cursor: pointer;
`
const DarkOpenButton = styled.button`
  z-index: 10;
  display: block;
  height: 20px;
  width: 20px;
  background: url(${menuIconDark});
  background-size: contain;
  background-repeat: no-repeat;
  border: 0;
  outline: 0;
  cursor: pointer;
  ${props =>
    props.dark &&
    css`
      background: none;
      background: url(${menuIconLight});
      background-size: contain;
      background-repeat: no-repeat;
    `}
`

export function ResponsiveMenu(props) {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <>
      <OpenButton
        onClick={() => {
          setIsOpen(true)
        }}
        type="link"
      />
      <Modal
        isOpen={isOpen}
        onRequestClose={() => {
          setIsOpen(false)
        }}
        style={customStyles}
      >
        <Wrapper>
          <CloseButton
            onClick={() => {
              setIsOpen(false)
            }}
          />
          <LogoWrapper>
            <Logo></Logo>
          </LogoWrapper>
          <MenuWrapper>{props.children}</MenuWrapper>
        </Wrapper>
      </Modal>
    </>
  )
}
export default ResponsiveMenu

export function NewResponsiveMenu(props) {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <>
      <DarkOpenButton
        dark={props.dark}
        onClick={() => {
          setIsOpen(true)
        }}
        type="link"
      />
      <Modal
        isOpen={isOpen}
        onRequestClose={() => {
          setIsOpen(false)
        }}
        style={customStyles}
      >
        <NewWrapper>
          <DarkCloseButton
            onClick={() => {
              setIsOpen(false)
            }}
          />
          <LogoWrapper>
            <Logo logo="dark" />
          </LogoWrapper>
          <DarkMenuWrapper open={props.open}>{props.children}</DarkMenuWrapper>
        </NewWrapper>
      </Modal>
    </>
  )
}
