import React from "react"
import styled from "styled-components"
import { rem } from "src/utils/styling"
import RightDarkImage from "src/images/icons/right_dark.svg"
import LeftDarkImage from "src/images/icons/left_dark.svg"

const CaretImage = styled.img`
  height: ${rem(35)}rem;
  width: ${rem(35)}rem;
`

export const DarkSliderRightArrow = ({ className, style, onClick }) => (
  <CaretImage
    src={RightDarkImage}
    alt="arrow-right"
    className={className}
    onClick={onClick}
    style={{ ...style }}
  />
)

export const DarkSliderLeftArrow = ({ className, style, onClick }) => (
  <CaretImage
    src={LeftDarkImage}
    alt="arrow-left"
    className={className}
    onClick={onClick}
    style={{ ...style }}
  />
)

export const settings = {
  dots: false,
  prevArrow: <DarkSliderLeftArrow />,
  nextArrow: <DarkSliderRightArrow />,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
}
