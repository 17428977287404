import React, { useRef } from "react"
import CaseStudyCard from "src/components/CaseStudy/CaseStudyCard"
import styled from "styled-components"
import TitleAndBody from "src/components/TitleAndBody"
import caseStudyData from "src/data/caseStudyData"
import Slider from "react-slick"
import { settings } from "src/components/Slider"
import { MobileOnlyCarousel } from "../Carousel"
import { DEVICE_TYPE_DESKTOP, useDeviceType } from "src/utils/deviceType"

const Container = styled.div`
  margin: 50px 0 100px 0;
  @media screen and (min-width: 1100px) {
    margin: 20px 200px 0 200px;
  }
  @media screen and (max-width: 550px) {
    margin: 10px 0;
  }
`

const CaseStudies = ({ page, index }) => {
  const deviceType = useDeviceType()
  const slider = useRef()

  if (slider.current) {
    slider.current.slickGoTo(index)
  }

  return (
    <Container>
      <TitleAndBody title="Case studies" />
      {deviceType === DEVICE_TYPE_DESKTOP ? (
        <Slider ref={slider} {...settings}>
          {caseStudyData
            .filter(el => el.for.includes(page))
            .map(
              ({
                title,
                toolsUsed,
                readMoreLink,
                companyLogo,
                description,
                type,
                typeText,
                statCards,
                testimonialSection: {
                  personImage,
                  personName,
                  personPosition,
                  testimonial,
                },
              }) => (
                <CaseStudyCard
                  key={type}
                  statCards={statCards}
                  left={{
                    toolsUsed,
                    title,
                    readMoreLink,
                    typeText,
                    type,
                    description,
                    companyLogo,
                  }}
                  right={{
                    personPosition,
                    personImage,
                    personName,
                    testimonial,
                  }}
                />
              )
            )}
        </Slider>
      ) : (
        <MobileOnlyCarousel goTo={index}>
          {caseStudyData
            .filter(el => el.for.includes(page))
            .map(
              ({
                title,
                toolsUsed,
                readMoreLink,
                companyLogo,
                description,
                type,
                typeText,
                statCards,
                testimonialSection: {
                  personImage,
                  personName,
                  personPosition,
                  testimonial,
                },
              }) => (
                <CaseStudyCard
                  key={type}
                  statCards={statCards}
                  left={{
                    toolsUsed,
                    title,
                    readMoreLink,
                    typeText,
                    type,
                    description,
                    companyLogo,
                  }}
                  right={{
                    personPosition,
                    personImage,
                    personName,
                    testimonial,
                  }}
                />
              )
            )}
        </MobileOnlyCarousel>
      )}
    </Container>
  )
}

export default CaseStudies
